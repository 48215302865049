<h1 class="headline-l" i18n="@@FILE_BROWSER.TITLE.FILE_BROWSER">File browser</h1>
<h2 class="title-l file-browser-org-name">{{ orgId }}</h2>
<div class="vc-file-browser-container vc-grid-layout vc-form-container-borderless">
    <vc-tree
        #fileBrowserTree
        class="col-4 vc-file-browser-tree vc-form-container"
        filterLabel="Search by folder name"
        i18n-filterLabel="@@FILE_BROWSER.TITLE.SEARCH_BY_FOLDER_NAME"
        filterPlaceholder=""
        [vcProgressSpinner]="treeLoading"
        [data]="folderTreeNodes"
        [itemRenderer]="treeItemRenderer"
        [highlighted]="highlightedNode"
        (highlightedChange)="highlightedChange($event)"
        [highlightable]="true"
        [expandAll]="false">
        <ng-template let-folder #treeNodeEnd>
            <vc-action-menu
                style="margin-left: auto"
                [actions]="folderActions"
                (open)="setFolderActions(folder)"
                (trigger)="folderActionClick($event, folder)"></vc-action-menu>
        </ng-template>
    </vc-tree>
    <vc-table
        class="col-8 vc-file-browser-table"
        [loading]="filesLoading"
        [data]="files"
        [showFilter]="false"
        [showPagination]="false"
        [showColumnSelector]="false">
        <vc-input
            tableActionRightSide
            i18n-label="@@FILE_BROWSER.TITLE.SEARCH_BY"
            label="Search by file, name and notes"
            [(value)]="fileCriteriaDTO.basic"
            [prefixIcon]="'search'"
            [showClear]="true"
            (valueChange)="searchFiles()"></vc-input>
        <ng-template
            vcColumn
            i18n-header="@@FILE_BROWSER.LABEL.FILE"
            header="File"
            field="filename"
            [sortable]="true"
            let-file>
            <vc-tooltip-text [text]="file.filename"></vc-tooltip-text>
        </ng-template>
        <ng-template
            vcColumn
            i18n-header="@@FILE_BROWSER.LABEL.NAME"
            header="Name"
            field="name"
            [sortable]="true"
            let-file>
            <vc-tooltip-text [text]="file.name"></vc-tooltip-text>
        </ng-template>
        <ng-template
            vcColumn
            i18n-header="@@FILE_BROWSER.LABEL.NOTES"
            header="Notes"
            field="notes"
            [sortable]="true"
            let-file>
            <vc-tooltip-text [text]="file.notes"></vc-tooltip-text>
        </ng-template>
        <ng-template vcColumn i18n-header="@@FILE_BROWSER.LABEL.TAGS" header="Tags" field="tags" let-file>
            <vc-chip-tags [tags]="file.tags" [showCount]="1"></vc-chip-tags>
        </ng-template>
        <ng-template
            vcColumn
            i18n-header="@@FILE_BROWSER.LABEL.CREATED"
            header="Created"
            field="createdInstant"
            [sortable]="true"
            let-file>
            <vc-tooltip-text [text]="(file.createdInstant | date : 'yyyy-MM-dd h:mm:ss') ?? ''"></vc-tooltip-text>
        </ng-template>
        <ng-template
            vcColumn
            i18n-header="@@FILE_BROWSER.LABEL.LAST_UPDATE"
            header="Last update"
            field="lastUpdatedInstant"
            [sortable]="true"
            let-file>
            <vc-tooltip-text [text]="(file.lastUpdatedInstant | date : 'yyyy-MM-dd h:mm:ss') ?? ''"></vc-tooltip-text>
        </ng-template>
        <ng-template vcColumn header="Action" i18n-header="@@COMMON.HEADER.ACTION" field="action" let-file>
            <vc-action-menu [actions]="fileActions" (trigger)="fileActionClick($event, file)"></vc-action-menu>
        </ng-template>
    </vc-table>
</div>

<vc-dialog
    [(visible)]="deleteFolderConfDialog"
    i18n-title="@@FILE_BROWSER.TITLE.DELETE_FOLDER"
    title="Delete folder"
    primaryLabel="Delete"
    i18n-primaryLabel="@@COMMON_UI.DELETE"
    [closeOnSecondary]="true"
    [showCloseIcon]="true"
    [closeOnPrimary]="true"
    (primaryTrigger)="onFolderDelete()">
    {{ deleteConfMsg }}
</vc-dialog>
<vc-dialog
    [(visible)]="deleteFileConfDialog"
    i18n-title="@@FILE_BROWSER.TITLE.DELETE_FILE"
    title="Delete file"
    primaryLabel="Delete"
    i18n-primaryLabel="@@COMMON_UI.DELETE"
    [closeOnSecondary]="true"
    [showCloseIcon]="true"
    [closeOnPrimary]="true"
    (primaryTrigger)="onFileDelete()">
    {{ deleteConfMsg }}
</vc-dialog>

<vc-file-browser-folder-dialog
    [(visible)]="showAddFolderDialog"
    [folder]="selectedFolder"
    [orgId]="orgId"
    (onSaveSuccess)="getFolderTree(true)"></vc-file-browser-folder-dialog>

<vc-file-browser-upload-zip-dialog
    [(visible)]="showUploadZipDialog"
    [folder]="selectedFolder"
    [orgId]="orgId"
    (onSaveSuccess)="getFolderTree(true)"></vc-file-browser-upload-zip-dialog>

<vc-file-browser-form-dialog
    [(visible)]="showFormDialog"
    [isNew]="isNewFile"
    [orgId]="orgId"
    [folder]="selectedFolder"
    [file]="selectedFile"
    (onSaveSuccess)="searchFiles()">
</vc-file-browser-form-dialog>
