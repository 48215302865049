<vc-dialog
    [(visible)]="visible"
    [loading]="loading"
    [width]="'600px'"
    [title]="title"
    [showActionsOnLeft]="true"
    [showActionsOnRight]="false"
    [closeOnSecondary]="true"
    [clearFormOnClose]="true"
    (primaryTrigger)="save($event)">
    <div class="title-L space-bottom-16">{{ location }}</div>
    <vc-common-form [showButtons]="false">
        <vc-file-uploader
            *ngIf="isNew"
            #fileUploaderComponent
            class="space-bottom-16"
            [maxFileSize]="150000000"
            (selectionChange)="uploadFiles($event)"></vc-file-uploader>
        <vc-input
            label="Name"
            i18n-label="@@FILE_BROWSER.LABEL.NAME"
            [required]="true"
            [readonly]="!isNew"
            [maxLength]="255"
            [(value)]="file.name"></vc-input>
        <vc-common-textarea
            label="Description"
            i18n-label="@@FILE_BROWSER.LABEL.DESCRIPTION"
            [(value)]="file.description"
            [maxLength]="4000"
            [showMax]="true"
            [resizable]="true"></vc-common-textarea>
        <vc-common-textarea
            label="Notes"
            i18n-label="@@FILE_BROWSER.LABEL.NOTES"
            [(value)]="file.notes"
            [maxLength]="4000"
            [showMax]="true"
            [resizable]="true"></vc-common-textarea>
        <vc-tags-selector
            #tagsSelector
            label="Tags"
            i18n-label="@@FILE_BROWSER.LABEL.TAGS"
            [(values)]="tags"
            [showClear]="true"></vc-tags-selector>
    </vc-common-form>
</vc-dialog>
