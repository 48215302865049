<div class="vc-tag-selector-wrapper" [style.padding-bottom.em]="minimalStyles ? 0 : 1.34375">
    <mat-form-field
        class="vc-tags-autocomplete-container vc-mat-form-field"
        [class.show-description]="showAddDescription"
        appearance="fill"
        [class.mat-form-field-disabled]="readonly || disabled"
        [class.mat-form-field-invalid]="!valid && touched"
        [class.vc-filter-minimal-styles]="minimalStyles">
        <mat-label>{{ label }}</mat-label>
        <mat-chip-grid #chipList [attr.aria-label]="label">
            <mat-chip-row
                *ngFor="let val of values"
                color="accent"
                class="vc-tag-selector-chip"
                [matTooltipPosition]="'above'"
                [matTooltip]="val.displayValue"
                (removed)="remove(val)">
                <mat-icon
                    aria-hidden="false"
                    class="vc-tag-selector-chip-icon"
                    [fontIcon]="val.prefix | tagIcon"
                    [attr.aria-label]="val.name"></mat-icon>
                <span class="vc-tag-selector-chip-label">
                    {{ val.name }}
                </span>
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input
                #autocompleteInput
                [(ngModel)]="filterValue"
                [placeholder]="placeholder"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [attr.aria-invalid]="!valid"
                [attr.aria-describedby]="ariaDescribedBy"
                [readonly]="readonly"
                [disabled]="disabled"
                [required]="required"
                (blur)="touched = true"
                (ngModelChange)="inputValueChanges()"
                (matChipInputTokenEnd)="add($event)" />
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectValue($event)">
            <mat-optgroup *ngFor="let group of filteredTags" class="vc-tag-group-sticky-header">
                <mat-icon
                    aria-hidden="false"
                    [style.color]="'var(--secondary-800)'"
                    [style.margin-right]="'8px'"
                    [style.min-width]="'22px'"
                    [fontIcon]="group.prefix | tagIcon"
                    [attr.aria-label]="group.prefix"></mat-icon>
                <vc-tooltip-text [text]="group | tagGroup" [style.overflow]="'auto'"></vc-tooltip-text>
                <mat-option *ngFor="let tag of group.tags" [value]="tag">
                    <vc-tooltip-text [text]="tag.name" [tooltipMessage]="tag.name"></vc-tooltip-text>
                </mat-option>
            </mat-optgroup>
        </mat-autocomplete>
        <div matSuffix class="vc-field-suffix">
            <mat-icon *ngIf="suffixIcon && values?.length === 0" style="font-size: 21px">{{ suffixIcon }}</mat-icon>
            <vc-button
                *ngIf="values && values.length > 0 && showClear && !disabled && !readonly"
                iconColor="var(--text-high-emphasis)"
                ariaLabel="Clear input"
                iconName="close"
                mode="icon"
                (trigger)="$event.stopPropagation(); clear()"></vc-button>
        </div>
        <mat-hint *ngIf="!valid && touched; else instructions" class="mat-error vc-error-message">{{
            errorMessage
        }}</mat-hint>
        <ng-template #instructions>
            <mat-hint *ngIf="showAddDescription" class="vc-tags-description" i18n="@@CORE.ADD_TAGS_DESC">
                To add a tag, you can start typing and the system will search for existing tags, then select the
                appropriate tag. If you are creating a new tag, continue typing until completion and then press enter.
            </mat-hint>
        </ng-template>
    </mat-form-field>
</div>
