import { Component, ViewChild } from '@angular/core';
import { ActionItem, NotificationService, TreeComponent, TreeNode } from '@libs/vc-common-ui-lib';
import { FileOrFolder, StringUtil, UserContextService, WhoAmI } from '@libs/vc-core-lib';
import { Subject, finalize, take, takeUntil } from 'rxjs';
import { IFolderTreeModel } from '../../common/models/folder-tree.model';
import { FileLockerService, IFileCriteriaDTO, IFileModel, IFilePathModel } from '@libs/vc-file-locker-lib';
import { isEmpty, isUndefined, omitBy } from 'lodash-es';

@Component({
    selector: 'vc-file-browser-search',
    templateUrl: './file-browser-search.component.html',
    styleUrls: ['./file-browser-search.component.scss'],
    providers: [FileLockerService],
})
export class FileBrowserSearchComponent {
    private _fetchDebouncer!: NodeJS.Timeout;
    private _destroy$: Subject<void> = new Subject();

    filesLoading = false;
    treeLoading = false;
    orgId!: string;
    folderTreeNodes: TreeNode<IFolderTreeModel>[] = [];
    highlightedNode: IFolderTreeModel | null = null;
    highlightedTreeNode: string | null = null;
    fileCriteriaDTO: IFileCriteriaDTO = {} as IFileCriteriaDTO;
    files: Array<IFileModel> = [];
    selectedFile: IFileModel = {} as IFileModel;
    selectedFolder!: IFolderTreeModel;
    folderActions: Array<ActionItem<FileAction>> = [];
    deleteFileConfDialog: boolean = false;
    deleteFolderConfDialog: boolean = false;
    showAddFolderDialog: boolean = false;
    showUploadZipDialog: boolean = false;
    showFormDialog: boolean = false;
    isNewFile: boolean = true;
    deleteConfMsg: string = '';
    fileActions: ActionItem<FileAction>[] = [
        {
            action: FileAction.DOWNLOAD,
            label: $localize`:@@FILE_BROWSER.LABEL.DOWNLOAD:Download file`,
        },
        {
            action: FileAction.EDIT_FILE,
            label: $localize`:@@FILE_BROWSER.LABEL.EDIT_FILE:Edit file`,
        },
        {
            action: FileAction.DELETE,
            label: $localize`:@@FILE_BROWSER.LABEL.DELETE:Delete`,
        },
    ];

    @ViewChild('fileBrowserTree')
    fileBrowserTree!: TreeComponent<IFolderTreeModel>;

    constructor(
        private _userContextService: UserContextService,
        private _fileLockerService: FileLockerService,
        private _notificationService: NotificationService
    ) {
        this._userContextService
            .getContext()
            .pipe(takeUntil(this._destroy$))
            .subscribe((userContext: WhoAmI | null) => {
                if (userContext) {
                    this.orgId = userContext?.getOrgId();
                    this.getFolderTree();
                    this.fileCriteriaDTO = {} as IFileCriteriaDTO;
                    this.fileCriteriaDTO.orgId = this.orgId;
                    this.fileCriteriaDTO.fileOrFolder = FileOrFolder.FILE;
                    this.searchFiles();
                }
            });
    }

    treeItemRenderer = (value: IFolderTreeModel) => value?.name ?? '';

    getFolderTree(searchFile: boolean = false): void {
        this.treeLoading = true;
        this.fileBrowserTree?.clearFilter();
        this._fileLockerService
            .getFilePaths(this.orgId)
            .pipe(
                take(1),
                finalize(() => (this.treeLoading = false))
            )
            .subscribe({
                next: (responsePath: IFilePathModel) => {
                    this.folderTreeNodes = [];
                    const folderTree = {
                        name: '/',
                        path: '/',
                    } as IFolderTreeModel;
                    this.highlightedNode = folderTree;
                    this.folderTreeNodes.push({
                        data: folderTree,
                        children: this.getChildrenTree(responsePath.children),
                        selectable: true,
                        iconPrefix: 'folder',
                    } as TreeNode<IFolderTreeModel>);

                    if (searchFile) {
                        this.searchFiles();
                    }

                    setTimeout(() => {
                        // Expand top level folder by default
                        !isEmpty(this.folderTreeNodes) && this.fileBrowserTree?.expandNode(this.folderTreeNodes[0]);

                        // Expand back down to selected node after folder/file changes
                        this.highlightedTreeNode && this.highlightNodeByPath(this.highlightedTreeNode);
                    }, 0);
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.FOLDER.FETCH_ERROR:Error while fetching the folders.`
                    );
                },
            });
    }

    private getChildrenTree(children: Array<IFilePathModel>) {
        const childTreeNodes: Array<TreeNode<IFolderTreeModel>> = [];
        children?.forEach((item: IFilePathModel) => {
            const index: number = item.path.lastIndexOf('/');
            const childNode = {
                data: {
                    name: item.path.substring(index + 1),
                    path: item.path,
                } as IFolderTreeModel,
                children: this.getChildrenTree(item.children),
                selectable: true,
                iconPrefix: 'folder',
            } as TreeNode<IFolderTreeModel>;
            childTreeNodes.push(childNode);
        });
        return childTreeNodes;
    }

    searchFiles() {
        this.filesLoading = true;
        this.fileCriteriaDTO.path = this.highlightedNode?.path ?? '/';
        this.fileCriteriaDTO = omitBy<IFileCriteriaDTO>(this.fileCriteriaDTO, isUndefined) as IFileCriteriaDTO;

        clearTimeout(this._fetchDebouncer);
        this._fetchDebouncer = setTimeout(() => {
            if (this.fileCriteriaDTO.basic && StringUtil.isNotBlank(this.fileCriteriaDTO.basic)) {
                this.searchWithoutPagination();
            } else {
                this.listByPaths();
            }
        }, 500);
    }

    private searchWithoutPagination(): void {
        this._fileLockerService
            .searchWithoutPagination(this.fileCriteriaDTO)
            .pipe(
                take(1),
                finalize(() => (this.filesLoading = false))
            )
            .subscribe({
                next: (files: Array<IFileModel>) => {
                    this.files = files;
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.FOLDER.FETCH_ERROR:Error while fetching the folders.`
                    );
                },
            });
    }
    private listByPaths(): void {
        this._fileLockerService
            .listByPaths(this.orgId, this.fileCriteriaDTO.path)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.filesLoading = false))
            )
            .subscribe({
                next: (files: Array<IFileModel>) => {
                    this.files = files;
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.FILE.FETCH_ERROR:Error while fetching the files.`
                    );
                },
            });
    }

    highlightedChange(folderTree: IFolderTreeModel | null) {
        this.highlightedNode = folderTree;
        this.highlightedTreeNode = folderTree?.path ?? null;
        this.fileCriteriaDTO.basic && StringUtil.isNotBlank(this.fileCriteriaDTO.basic)
            ? (this.fileCriteriaDTO.basic = '')
            : this.searchFiles();
    }

    setFolderActions(folder: IFolderTreeModel) {
        this.folderActions = [
            {
                action: FileAction.UPLOAD_ZIP,
                label: $localize`:@@FILE_BROWSER.LABEL.UPLOAD_ZIP:Upload ZIP`,
            },
            {
                action: FileAction.ADD_FILE,
                label: $localize`:@@FILE_BROWSER.LABEL.ADD_FILE:Add file`,
            },
            {
                action: FileAction.ADD_FOLDER,
                label: $localize`:@@FILE_BROWSER.LABEL.ADD_FOLDER:Add folder`,
            },
            {
                action: FileAction.DELETE,
                visible: folder.path !== '/',
                label: $localize`:@@FILE_BROWSER.LABEL.DELETE:Delete`,
            },
        ];
    }

    folderActionClick(action: ActionItem<FileAction>, folder: IFolderTreeModel) {
        this.highlightedChange(folder);
        switch (action.action) {
            case FileAction.UPLOAD_ZIP:
                this.selectedFolder = folder;
                this.showUploadZipDialog = true;
                break;
            case FileAction.ADD_FILE:
                this.selectedFolder = folder;
                this.isNewFile = true;
                this.showFormDialog = true;
                break;
            case FileAction.ADD_FOLDER:
                this.selectedFolder = folder;
                this.showAddFolderDialog = true;
                break;
            case FileAction.DELETE:
                this.selectedFolder = folder;
                this.deleteConfMsg = $localize`:@@FILE_BROWSER.FOLDER.DELETE.DYNAMIC_MESSAGE:Are you sure you want to delete ${this.selectedFolder.name} as it will delete all folders under this folder?`;
                this.deleteFolderConfDialog = true;
                break;
            default:
                break;
        }
    }

    fileActionClick(actionItem: ActionItem<FileAction>, file: IFileModel) {
        switch (actionItem.action) {
            case FileAction.DOWNLOAD:
                this.downloadFile(file);
                break;
            case FileAction.EDIT_FILE:
                this.selectedFile = file;
                this.isNewFile = false;
                this.showFormDialog = true;
                break;
            case FileAction.DELETE:
                this.selectedFile = file;
                this.deleteConfMsg = $localize`:@@CORE.BUTTON.DELETE.DYNAMIC_MESSAGE:Are you sure you want to delete ${this.selectedFile.filename} ?`;
                this.deleteFileConfDialog = true;
                break;
            default:
                break;
        }
    }

    downloadFile(file: IFileModel) {
        this.filesLoading = true;
        this._fileLockerService
            .downloadFile(file.id)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.filesLoading = false))
            )
            .subscribe({
                next: (response: Blob) => {
                    const downloadURL = URL.createObjectURL(response as Blob);
                    const link = document.createElement('a');
                    link.href = downloadURL;
                    link.download = file.filename;
                    link.click();
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.DOWNLOAD.FETCH_ERROR:Error while fetching the file for download.`
                    );
                },
            });
    }

    onFileDelete(): void {
        this.filesLoading = true;
        this._fileLockerService
            .deleteFile(this.selectedFile.id)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.filesLoading = false))
            )
            .subscribe({
                next: () => {
                    this.searchFiles();
                    this._notificationService.success(
                        '',
                        $localize`:@@FILE_BROWSER.DELETE_FILE.DELETE_NOTIFICATION:File deleted successfully`
                    );
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.DELETE_FILE.DELETE_ERROR:Error while deleting the file`
                    );
                },
            });
    }

    onFolderDelete(): void {
        this.filesLoading = true;
        this._fileLockerService
            .deleteDirectory(this.selectedFolder.path)
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => (this.filesLoading = false))
            )
            .subscribe({
                next: () => {
                    this.getFolderTree(true);
                    this._notificationService.success(
                        '',
                        $localize`:@@FILE_BROWSER.DELETE_FOLDER.DELETE_NOTIFICATION:Folder deleted successfully`
                    );
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.DELETE_FOLDER.DELETE_ERROR:Error while deleting the folder`
                    );
                },
            });
    }

    highlightNodeByPath(path: string) {
        const treeNode = this._searchNodesAndExpand(this.folderTreeNodes[0].children, path);
        treeNode?.data && this.highlightedChange(treeNode?.data);
    }

    private _searchNodesAndExpand = (
        nodes: TreeNode<IFolderTreeModel>[] | undefined,
        path: string
    ): TreeNode<IFolderTreeModel> | undefined => {
        if (!Array.isArray(nodes)) return;

        const folders = path.split('/').slice(1);
        let found!: TreeNode<IFolderTreeModel> | undefined;

        for (let folder of folders) {
            let treeNode: TreeNode<IFolderTreeModel> | undefined = nodes.find((node) => node.data?.name === folder);
            if (treeNode) {
                this.fileBrowserTree?.expandNode(treeNode);
                if (treeNode.children && treeNode.children.length > 0) {
                    nodes = treeNode.children;
                }
                found = treeNode;
            }
        }

        return found;
    };
}

enum FileAction {
    UPLOAD_ZIP,
    ADD_FILE,
    ADD_FOLDER,
    DOWNLOAD,
    EDIT_FILE,
    DELETE,
}
