import { Injectable, Injector } from '@angular/core';
import { BaseService, IFacetedPageDTO, IKeyValueGeneric } from '@libs/vc-core-lib';
import { IAssetModel } from '../model/asset.model';
import { forkJoin, Observable } from 'rxjs';
import { IAssetSearchDTO, IGetAssetsDTO } from '../dto/asset.search.dto';
import { IAssetCriteriaDTO } from '../dto/asset.criteria.dto';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AssetService extends BaseService<IAssetModel> {
    constructor(protected injector: Injector) {
        super(injector, 'asset');
    }

    public getAssets(body: IGetAssetsDTO): Observable<IAssetModel[]> {
        const url = `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/search`;
        return this.httpClient.post<IAssetModel[]>(url, body);
    }

    public saveAsset(model: IAssetModel): Observable<string> {
        const url = `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}`;
        return this.httpClient.post<string>(url, model, { observe: 'body', responseType: 'text' as 'json' });
    }

    public assetFacetedSearch(searchDTO: IAssetSearchDTO): Observable<IFacetedPageDTO<IAssetModel>> {
        const url = `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/pageSearch`;
        return this.httpClient.post<IFacetedPageDTO<IAssetModel>>(url, searchDTO);
    }

    public saveAllAssets(assetModels: Array<IAssetModel>): Observable<string[]> {
        const calls = assetModels.map((assetModel) =>
            this.httpClient.post(`${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}`, assetModel, {
                responseType: 'text',
            })
        );
        return forkJoin(calls);
    }

    public searchAssetsWithoutPaging(searchDTO: IAssetCriteriaDTO): Observable<IAssetModel[]> {
        const url = `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/search`;
        return this.httpClient.post<IAssetModel[]>(url, searchDTO);
    }

    public getNewestAssets(from: string, to: string): Observable<Array<IAssetModel>> {
        return this.httpClient.get<Array<IAssetModel>>(
            `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/newest`,
            { params: new HttpParams().set('from', from).set('to', to) }
        );
    }

    public getsRiskiestAssets(from: string, to: string): Observable<Array<IAssetModel>> {
        return this.httpClient.get<Array<IAssetModel>>(
            `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/riskiest`,
            { params: new HttpParams().set('from', from).set('to', to) }
        );
    }

    public getQuietestAssets(from: string, to: string): Observable<Array<IAssetModel>> {
        return this.httpClient.get<Array<IAssetModel>>(
            `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/quietest`,
            { params: new HttpParams().set('from', from).set('to', to) }
        );
    }

    public deleteAssets(assets: Array<IAssetModel>): Observable<void[]> {
        const calls = assets.map((asset) =>
            this.httpClient.delete<void>(`${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/${asset.id}`)
        );
        return forkJoin(calls);
    }

    public getAssetsCountByType(from: string, to: string): Observable<IKeyValueGeneric> {
        return this.httpClient.get<IKeyValueGeneric>(
            `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/countByType`,
            { params: new HttpParams().set('from', from).set('to', to) }
        );
    }

    public getAssetsTotalCount(from: string, to: string): Observable<number> {
        return this.httpClient.get<number>(
            `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/totalCount`,
            { params: new HttpParams().set('from', from).set('to', to) }
        );
    }

    public getAsset(id: string): Observable<IAssetModel> {
        return this.httpClient.get<IAssetModel>(
            `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/${id}`
        );
    }

    public getAssetByKey(key: string, org: string): Observable<IAssetModel> {
        return this.httpClient.get<IAssetModel>(
            `${this.environment.APP.VC_ASSET_BASE_URL}/${this.serviceBaseUrl}/key/${key}`,
            { params: new HttpParams().set('org', org) }
        );
    }
}
