import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IChangePasswordModel, UserService } from '@libs/vc-core-lib';
import { finalize, take } from 'rxjs';
import { FormComponent, NotificationService, VcCommonUiLibModule } from '@libs/vc-common-ui-lib';
import { NgIf } from '@angular/common';

@Component({
    standalone: true,
    selector: 'vc-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss'],
    providers: [UserService],
    imports: [VcCommonUiLibModule, NgIf],
})
export class ChangePasswordDialogComponent {
    resetPasswordText = $localize`:@@ACCOUNT.RESET_PASSWORD:Reset password`;
    changePasswordText = $localize`:@@ACCOUNT.CHANGE_PASSWORD:Change password`;
    changeText = $localize`:@@ACCOUNT.CHANGE:Change`;

    loading = false;

    currentPassword = '';
    newPassword = '';
    confirmPassword = '';

    @Input()
    visible = false;

    @Input()
    username!: string;

    /* Whether this dialog should be forcing user to set new password (true) or changing the password is optional (false by default) */
    @Input()
    forceReset = false;

    /* Emitted when after password API call.  True if success, false if error.  */
    @Output()
    passwordChange = new EventEmitter<boolean>();

    @Output()
    visibleChange = new EventEmitter<boolean>();

    @ViewChild('changePasswordForm')
    changePasswordForm!: FormComponent;

    constructor(private _userService: UserService, private _notificationService: NotificationService) {}

    onChangePassword(valid: boolean) {
        if (!valid) return;

        this.loading = true;

        const model: IChangePasswordModel = {
            oldPassword: this.currentPassword,
            newPassword: this.newPassword,
        };

        this._userService
            .changeMyPassword(model)
            .pipe(
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe({
                next: () => {
                    this._notificationService.success(
                        '',
                        $localize`:@@ACCOUNT.SUCCESS_CHANGE_PASSWORD:Successfully changed password`
                    );
                    this.visible = false;
                    this.passwordChange.emit(true);
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@ACCOUNT.DETAILS.ERROR_SAVING_PASSWORD:There was an issue saving your new password.  Please try again.`
                    );
                    this.passwordChange.emit(false);
                },
            });
    }
}
