import { Component, EventEmitter, Input, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { FormComponent } from '../form/form.component';
import { MatSelect } from '@angular/material/select';

/** Example of dialog usage
 * HTML
 *    <vc-select label="My Select"
 *                [(value)]="mySelect"
 *                [options]="options"
 *                [itemRenderer]="selectItemRenderer"
 *                [required]="true"></vc-select>
 *
 * TS
 *  myselect: MyOptions | null = MyOptions.TWO;
 *  options = [MyOptions.ZERO, MyOptions.ONE, MyOptions.TWO, MyOptions.THREE, MyOptions.FOUR];
 *
 * selectItemRenderer = (value: MyOptions) => value;
 * export enum MyOptions {
 *     ZERO,
 *     ONE,
 *     TWO,
 *     THREE,
 *     FOUR,
 * }
 * */

@Component({
    selector: 'vc-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent<T> extends BaseFieldComponent<T> {
    @Input()
    set value(val: T | null) {
        if (val != this._value) {
            this._value = val;
            this.valueChange.emit(this._value);
            this.validate();
        }
    }

    get value(): T | null {
        return this._value;
    }

    private _value!: T | null;

    /** List of options for select dropdown */
    @Input()
    options!: any[];

    /** Select placeholder value */
    @Input()
    placeholder: string = '';

    /** Identifies the element (or elements) that describes the select. */
    @Input()
    ariaDescribedBy!: string;

    /** Identifies the element (or elements) that labels the select. */
    @Input()
    ariaLabeledBy!: string;

    /** Function for rendering options list and selected values */
    @Input()
    itemRenderer?: (data: T) => string;

    /** Options template renderer. */
    @Input()
    optionTemplate!: TemplateRef<T>;

    /** Prefix template renderer. */
    @Input()
    prefixTemplate!: TemplateRef<T>;

    /** Whether to show clear icon. */
    @Input()
    showClear: boolean = false;

    /** Suffix icon */
    @Input()
    suffixIcon!: string;

    @Input()
    minimalStyles: boolean = false;

    @Input()
    noPaddingBottom: boolean = false;

    /** Two ways data binding for input value */
    @Output()
    valueChange = new EventEmitter<T | null>();

    @ViewChild('selectInput')
    selectInput!: MatSelect;

    get readonlyValue(): string | null {
        return this.itemRenderer ? this.itemRenderer(this._value as T) : !!this._value ? `${this._value}` : null;
    }

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    onBlur() {
        this.touched = true;
        this.validate();
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required && (this._value === null || this._value === undefined || this._value === '')) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this._value);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.value = null);
        this.valid = true;
        this.errorMessage = '';
    }

    focus() {
        this.selectInput.focus();
    }

    getContextType(obj: any): T {
        return obj;
    }
}
