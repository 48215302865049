<div class="vc-phone-fields">
    <vc-common-autocomplete
        #codeInput
        class="vc-phone-code-field"
        [ariaDescribedBy]="codeInput?.errorMessage ? codeId : ''"
        [label]="codeLabel"
        [options]="codeOptions"
        [readonly]="readonly"
        [disabled]="disabled"
        [minimalStyles]="minimalStyles"
        [required]="codeRequired"
        [itemRenderer]="codeItemRenderer"
        [optionTemplate]="flagAndCodeTemplate"
        [prefixTemplate]="flagTemplate"
        [validationFunction]="codeValidationFunction"
        [(value)]="countryValue"
        (valueChange)="onChangeValue()"></vc-common-autocomplete>
    <vc-input
        #numberInput
        class="vc-phone-number-field"
        type="text"
        [ariaDescribedBy]="numberInput?.errorMessage ? numberId : ''"
        [label]="numberLabel"
        [readonly]="readonly"
        [minimalStyles]="minimalStyles"
        [required]="phoneRequired"
        [disabled]="disabled"
        [maxLength]="maxNumberLength"
        [minLength]="minNumberLength"
        [showClear]="showClearForNumber"
        [validateOnValueChange]="validateOnValueChange"
        [validationFunction]="numberValidationFunction"
        [(value)]="numberValue"
        (valueChange)="onChangeValue()"></vc-input>
    <vc-input
        #extInput
        *ngIf="showExt"
        class="vc-phone-ext-field"
        type="text"
        [ariaDescribedBy]="extInput?.errorMessage ? extId : ''"
        [label]="extLabel"
        [readonly]="readonly"
        [minimalStyles]="minimalStyles"
        [required]="extRequired"
        [disabled]="disabled"
        [maxLength]="maxExtLength"
        [minLength]="minExtLength"
        [validateOnValueChange]="validateOnValueChange"
        [validationFunction]="extValidationFunction"
        [(value)]="extValue"
        (valueChange)="onChangeValue()"></vc-input>
</div>
<ng-template #flagAndCodeTemplate let-country>
    <vc-country-flag [countryClass]="country?.alpha2?.toLowerCase() ?? ''"></vc-country-flag>
    <span>+{{ country?.phoneCode ?? '' }}</span>
</ng-template>
<ng-template #flagTemplate let-country>
    <vc-country-flag [countryClass]="country?.alpha2?.toLowerCase() ?? ''"></vc-country-flag>
</ng-template>
