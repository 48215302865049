import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

/** Example of overlay
 *
 * Apply directive to the element where you want the overlay to appear and reference a template containing what to put in the overlay.
 *
 * **TS**
 * ```typescript
 * @NgModule({
 *     declarations: [
 *         ...
 *     ],
 *     imports: [
 *         ...
 *         ChartEmptyComponent,
 *     ],
 * })
 * export class ExampleModule {}
 * ```
 *
 * **HTML**
 * ```html
 * <vc-chart-empty [noDataMsg]="Nothing to see here..." />
 * ```
 *
 * */

@Component({
    selector: 'vc-chart-empty',
    standalone: true,
    imports: [CommonModule, ButtonComponent],
    templateUrl: './chart-empty.component.html',
    styleUrl: './chart-empty.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartEmptyComponent {
    @Input()
    showPrimaryButton: boolean = false;

    @Input()
    showSecondaryButton: boolean = false;

    @Input()
    showChartLine: boolean = true;

    @Input()
    primaryLabel: string = $localize`:@@COMMON_UI.TABLE.RELOAD:Reload`;

    @Input()
    secondaryLabel: string = $localize`:@@COMMON_UI.TABLE.RELOAD:Reload`;

    @Input()
    noDataMsg: string = $localize`:@@COMMON_UI.TABLE.NO_DATA_AVAILABLE:No data available`;

    @Output()
    primaryTrigger: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    secondaryTrigger: EventEmitter<void> = new EventEmitter<void>();
}
