export abstract class RegexUtil {
    private static EMAIL_REGEX = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    private static NUMBER_REGEX: RegExp = new RegExp(/^[0-9]*$/);
    private static ALPHABET_REGEX: RegExp = new RegExp(/^[A-Za-z]+$/);
    private static ALPHA_NUMERIC_REGEX: RegExp = new RegExp(/^[a-zA-Z0-9]*$/);
    private static ALL_CAPS_ALPHA_NUMERIC_WITH_UNDERSCORES_REGEX: RegExp = new RegExp(/^[A-Z0-9_]+$/);
    private static PASSWORD_REGEX: RegExp = new RegExp(
        /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.\/:;\\<=>?@[\]^_`{|}~]).{8,32})$/
    );
    private static WHITE_SPACE_REGEX: RegExp = new RegExp(/\s/);
    private static IP_REGEX: RegExp = new RegExp(
        /((^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$)|(^((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?$))/
    );
    private static PORT_REGEX: RegExp = new RegExp(
        /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/
    );
    private static IPV4_CIDR_REGEX: RegExp = new RegExp(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))$/);
    private static IPV6_CIDR_REGEX: RegExp = new RegExp(
        /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))$/
    );
    private static FLOAT_NUMBER_REGEX: RegExp = new RegExp(/^[+-]?\d+(\.\d+)?$/);
    private static SINGLE_QUOTE_STR_REGEX: RegExp = new RegExp(/^'([\S]+[\s]*)+'$/);
    private static FILE_PATH_REGEX: RegExp = new RegExp(/^\/([A-z0-9-_+]+\/)*([A-z0-9-_+]+)$/);
    private static INVALID_FILE_NAME_REGEX: RegExp = RegExp(/[/\\?%*:|"<>]/g);
    private static MULTIPLE_EMAIL_REGEX: RegExp = new RegExp(
        /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;|\s|,\s*|\s*$))*$/
    ); // Emails can be separated by ",", " " or ";"
    private static HOSTNAME_REGEX: RegExp = new RegExp(
        /^(?!-)(?:[A-Za-z0-9-]{1,63}(?=\.)|[A-Za-z0-9-]{1,63})+(?<=\.[A-Za-z]{2,})$/
    );

    public static isValidEmail(value: string): boolean {
        return RegexUtil.EMAIL_REGEX.test(value);
    }

    public static isNotValidEmail(value: string): boolean {
        return !RegexUtil.EMAIL_REGEX.test(value);
    }

    public static areEmailsValid(value: string): boolean {
        return RegexUtil.MULTIPLE_EMAIL_REGEX.test(value);
    }

    public static areEmailsNotValid(value: string): boolean {
        return !RegexUtil.MULTIPLE_EMAIL_REGEX.test(value);
    }

    public static isValidNumber(value: string): boolean {
        return RegexUtil.NUMBER_REGEX.test(value);
    }

    public static isNotValidNumber(value: string): boolean {
        return !RegexUtil.isValidNumber(value);
    }

    public static isValidAlphabet(value: string): boolean {
        return RegexUtil.ALPHABET_REGEX.test(value);
    }

    public static isNotValidAlphabet(value: string): boolean {
        return !RegexUtil.isValidAlphabet(value);
    }

    public static isValidAlphaNumeric(value: string): boolean {
        return RegexUtil.ALPHA_NUMERIC_REGEX.test(value);
    }

    public static isNotValidAlphaNumeric(value: string): boolean {
        return !RegexUtil.isValidAlphaNumeric(value);
    }

    public static isValidAllCapsAlphaNumericWithUnderscores(value: string): boolean {
        return RegexUtil.ALL_CAPS_ALPHA_NUMERIC_WITH_UNDERSCORES_REGEX.test(value);
    }

    public static isValidPassword(value: string): boolean {
        return RegexUtil.PASSWORD_REGEX.test(value) && !RegexUtil.WHITE_SPACE_REGEX.test(value);
    }

    public static isNotValidPassword(value: string): boolean {
        return !RegexUtil.isValidPassword(value);
    }

    public static isValidIP(value: string): boolean {
        return RegexUtil.IP_REGEX.test(value);
    }

    public static isNotValidIP(value: string): boolean {
        return !RegexUtil.isValidIP(value);
    }

    public static isValidPort(value: string): boolean {
        return RegexUtil.PORT_REGEX.test(value);
    }

    public static isNotValidPort(value: string): boolean {
        return !RegexUtil.isValidPort(value);
    }

    public static isValidCIDR(value: string): boolean {
        return RegexUtil.IPV4_CIDR_REGEX.test(value) || RegexUtil.IPV6_CIDR_REGEX.test(value);
    }

    public static isNotValidCIDR(value: string): boolean {
        return !RegexUtil.isValidCIDR(value);
    }

    public static isValidRange(value: string): boolean {
        const ips = value.split('-');
        return RegexUtil.IP_REGEX.test(ips[0]) && RegexUtil.IP_REGEX.test(ips[1]);
    }

    public static isValidFloatNumber(value: string): boolean {
        return RegexUtil.FLOAT_NUMBER_REGEX.test(value);
    }

    public static isNotValidFloatNumber(value: string): boolean {
        return !RegexUtil.isValidFloatNumber(value);
    }

    public static isValidSingleQuoteStr(value: string): boolean {
        return RegexUtil.SINGLE_QUOTE_STR_REGEX.test(value);
    }

    public static isNotValidSingleQuoteStr(value: string): boolean {
        return !RegexUtil.isValidSingleQuoteStr(value);
    }

    public static isValidFilePath(value: string): boolean {
        return RegexUtil.FILE_PATH_REGEX.test(value);
    }

    public static isNotValidFilePath(value: string): boolean {
        return !RegexUtil.isValidFilePath(value);
    }

    public static isValidFolderName(value: string): boolean {
        return value.indexOf('/') === -1 && value.indexOf('\\') === -1;
    }

    public static isNotValidFolderName(value: string): boolean {
        return !RegexUtil.isValidFolderName(value);
    }

    public static getValidFileName(value: string, replaceValue: string): string {
        return value.replace(RegexUtil.INVALID_FILE_NAME_REGEX, replaceValue);
    }

    public static isValidHostname(value: string): boolean {
        return RegexUtil.HOSTNAME_REGEX.test(value);
    }
}
