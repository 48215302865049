import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@libs/vc-core-lib';
import { IFileCriteriaDTO } from '../dto/file.criteria.dto';
import { IFileArchiveModel } from '../model/file-archive.model';
import { IFileModel } from '../model/file.model';
import { Observable } from 'rxjs';
import { IFilePathModel } from '../model/file-path.model';
import { IFileBriefModel } from '../model/file-brief.model';
import { IBulkFileCopyModel } from '../model/bulk-file-copy.model';
import { IBulkFileUploadModel } from '../model/bulk-file-upload.model';

@Injectable()
export class FileLockerService extends BaseService<IFileModel> {
    constructor(protected injector: Injector) {
        super(injector, 'file');
    }

    public searchWithoutPagination(searchDTO: IFileCriteriaDTO): Observable<Array<IFileModel>> {
        return this.httpClient.post<Array<IFileModel>>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/search`,
            searchDTO
        );
    }

    public listByPaths(orgId: string, path: string): Observable<Array<IFileModel>> {
        let params = this.getHttpParams();
        params = params.set('path', path);
        return this.httpClient.post<Array<IFileModel>>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/listByPath/${orgId}`,
            null,
            { params: params }
        );
    }

    public deleteByFileIds(fileIds: Array<string>): Observable<any> {
        let params = this.getHttpParams();
        params = params.set('fileIds', fileIds.toString());
        return this.httpClient.post(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/deleteByIds`,
            null,
            {
                params: params,
            }
        );
    }

    public downloadFile(id: string): Observable<Blob> {
        return this.httpClient.get<Blob>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/download/${id}`,
            { observe: 'body', responseType: 'blob' as 'json' }
        );
    }

    public downloadAsArchiveFile(searchDTO: IFileCriteriaDTO): Observable<Blob> {
        return this.httpClient.post<Blob>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/downloadArchive`,
            searchDTO,
            { observe: 'body', responseType: 'blob' as 'json' }
        );
    }

    public createFileDirectory(dir: string, orgId: string, file: IFileModel | null): Observable<IFileModel> {
        let params = this.getHttpParams();
        params = params.set('dir', dir);
        params = params.set('orgId', orgId);
        return this.httpClient.post<IFileModel>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/createDir`,
            file,
            {
                params: params,
            }
        );
    }

    public deleteFile(id: string): Observable<void> {
        return this.httpClient.delete<void>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/${id}`
        );
    }

    public deleteDirectory(path: string): Observable<any> {
        let params = this.getHttpParams();
        params = params.set('path', path);
        return this.httpClient.post(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/delete-dir`,
            null,
            {
                params: params,
            }
        );
    }

    public getFilePaths(orgId: string): Observable<IFilePathModel> {
        return this.httpClient.get<IFilePathModel>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/paths/${orgId}`
        );
    }

    public copyListOfFiles(sourceFileIds: Array<string>, destFolderId: string): Observable<Array<IFileModel>> {
        let params = this.getHttpParams();
        params = params.set('fileIds', sourceFileIds.toString());
        params = params.set('folderId', destFolderId);
        return this.httpClient.post<Array<IFileModel>>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/copyFiles`,
            null,
            {
                params: params,
            }
        );
    }

    public createFile(file: IFileModel, resource: File): Observable<IFileModel> {
        const formData = new FormData();
        formData.set('resource', resource);
        formData.set('file', new Blob([JSON.stringify(file)], { type: 'application/json' }));
        return this.httpClient.post<IFileModel>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}`,
            formData
        );
    }

    public updateFile(id: string, file: IFileModel, resource: File | null): Observable<IFileModel> {
        const formData = new FormData();
        if (resource) {
            formData.set('resource', resource);
        }
        formData.set('file', new Blob([JSON.stringify(file)], { type: 'application/json' }));
        return this.httpClient.put<IFileModel>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/${id}`,
            formData
        );
    }

    public uploadFileArchive(resource: File, fileArchive: IFileArchiveModel): Observable<Array<IFileModel>> {
        const formData = new FormData();
        formData.set('resource', resource);
        formData.set('file', new Blob([JSON.stringify(fileArchive)], { type: 'application/json' }));
        return this.httpClient.post<Array<IFileModel>>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/uploadArchive`,
            formData
        );
    }

    public multipleFileUpload(resources: Array<File>, files: Array<IFileModel>): Observable<Array<IFileModel>> {
        const formData = new FormData();
        resources.forEach((resource) => formData.append('resource', resource));
        formData.set('file', new Blob([JSON.stringify(files)], { type: 'application/json' }));
        return this.httpClient.post<Array<IFileModel>>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/multiUpload`,
            formData
        );
    }

    public rename(fileId: string, fileName: string): Observable<any> {
        let params = this.getHttpParams();
        params = params.set('fileName', fileName);
        return this.httpClient.patch(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/${fileId}/rename`,
            null,
            {
                params: params,
            }
        );
    }

    public copyFilesToDirs(bulkFileCopy: IBulkFileCopyModel): Observable<IFileBriefModel[]> {
        return this.httpClient.post<IFileBriefModel[]>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/copyFilesToDirs`,
            bulkFileCopy
        );
    }

    public uploadArchiveToDirs(resource: File, bulkFileUpload: IBulkFileUploadModel): Observable<IFileBriefModel[]> {
        const formData = new FormData();
        formData.set('resource', resource);
        formData.set('file', new Blob([JSON.stringify(bulkFileUpload)], { type: 'application/json' }));
        return this.httpClient.post<IFileBriefModel[]>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/uploadArchiveToDirs`,
            formData
        );
    }

    public uploadFilesToDirs(resources: File[], bulkFileUpload: IBulkFileUploadModel): Observable<IFileBriefModel[]> {
        const formData = new FormData();
        resources.forEach((resource) => formData.append('resource', resource));
        formData.set('file', new Blob([JSON.stringify(bulkFileUpload)], { type: 'application/json' }));
        return this.httpClient.post<IFileBriefModel[]>(
            `${this.environment.APP.VC_FILE_LOCKER_BASE_URL}/${this.serviceBaseUrl}/uploadFilesToDirs`,
            formData
        );
    }
}
