<mat-form-field
    class="vc-textarea-container vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-readonly]="readonly"
    [class.mat-form-field-invalid]="!valid && touched"
    [class.vc-filter-minimal-styles]="minimalStyles">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <textarea
        matInput
        #textareaElement
        placeholder="{{ placeholder }}"
        [class.vc-textarea-disable-resize]="!resizable"
        [attr.aria-invalid]="!valid"
        [rows]="rows"
        [readonly]="readonly"
        [disabled]="disabled"
        [aria-describedby]="ariaDescribedBy"
        [required]="required"
        [(ngModel)]="value"
        (focus)="focusChange.emit()"
        (blur)="onBlur()"
        (keydown)="onKeyDown()"
        (keyup)="onKeyUp()"></textarea>
    <mat-icon *ngIf="suffixIcon && !value">{{ suffixIcon }}</mat-icon>
    <mat-hint class="vc-textarea-hint"
              [class.has-error]="!valid && touched">
        <span *ngIf="!valid && touched" class="mat-error vc-error-message">{{ errorMessage }}</span>
        <span *ngIf="showMax"
              class="vc-maxlength-label"
              [class.error]="(value ?? '').length > maxLength"
              [class.textarea-invalid]="!valid && touched">{{ maxCountLabel }}</span>
    </mat-hint>

</mat-form-field>
