import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'vcCurrency',
})
export class VCCurrencyPipe implements PipeTransform {
    transform(
        value: string | number,
        isoCode = 'USD',
        showParens = false,
        currencyDisplay = 'symbol',
        onlyNumber = false
    ): any {
        if (!value) {
            value = 0;
        }

        if (typeof value === 'string') {
            value = parseFloat(value);
        }

        if (onlyNumber) {
            return value.toLocaleString('en-US', { minimumFractionDigits: 2 });
        } else {
            return new Intl.NumberFormat('en-US', {
                localeMatcher: 'best fit',
                style: 'currency',
                currency: isoCode,
                currencyDisplay: currencyDisplay,
                currencySign: showParens ? 'accounting' : 'standard',
                maximumFractionDigits: 2,
            } as Intl.NumberFormatOptions).format(value);
        }
    }
}
