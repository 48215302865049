import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { allCountries, Country } from '../../common/countries';
import { Validation } from '../../common/validation';
import { ValidationFunction } from '../base-field/base-field';
import { InputComponent } from '../input/input.component';
import { IDGenerator } from '../../common/id-generator';
import { AutocompleteCommonComponent } from '../autocomplete/autocomplete.component';
import { PhoneUtil } from './phone.util';

/** Example of phone usage
 * HTML
 * <vc-phone [(value)]="number"></vc-phone>
 *
 *  TS
 *  number!: Phone;
 * */

export interface Phone {
    country?: Country | null;
    number?: string;
    extension?: string;
}

@Component({
    selector: 'vc-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent {
    countryValue: Country | null = null;
    numberValue: string = '';
    extValue: string = '';

    codeId: string = `code-input-descr${IDGenerator.generateID()}`;
    numberId: string = `phone-input-descr${IDGenerator.generateID()}`;
    extId: string = `ext-input-descr${IDGenerator.generateID()}`;

    @Input()
    set value(val: Phone) {
        if (val != this._value) {
            this._value = val;
            this.countryValue = val.country ?? null;
            this.numberValue = val.number ?? '';
            this.extValue = val.extension ?? '';
            this.valueChange.emit(this._value);
        }
    }

    get value(): Phone {
        return this._value;
    }

    private _value!: Phone;

    /** Country-Code options */
    @Input()
    codeOptions: Country[] = allCountries;

    /** Code field label */
    @Input()
    codeLabel: string = $localize`:@@CORE.INPUT.COUNTRY:Country`;

    /** Number field label */
    @Input()
    numberLabel: string = $localize`:@@CORE.INPUT.PHONE_NUMBER:Phone number`;

    /** Extension field label */
    @Input()
    extLabel: string = $localize`:@@CORE.INPUT.EXTENSION:Extension`;

    /** Whether the code, number and extension fields are disabled. */
    @Input()
    disabled: boolean = false;

    /** Whether the code and number fields are required. */
    @Input()
    required: boolean = false;

    /** Whether the extension is required. */
    @Input()
    extRequired: boolean = false;

    /** Whether the code, number and extension fields are readonly. */
    @Input()
    readonly: boolean = false;

    /** Whether to show clear icon for number field. */
    @Input()
    showClearForNumber: boolean = false;

    /** Whether to show extension field. */
    @Input()
    showExt: boolean = false;

    /** Whether to validate field on value changes. */
    @Input()
    validateOnValueChange: boolean = false;

    /** Whether to apply minimal styles(no border, background, underline etc)  */
    @Input()
    minimalStyles: boolean = false;

    /** Minimum allowed characters for number field */
    @Input()
    minNumberLength!: number;

    /** Maximum allowed characters for number field. Default max is 40 */
    @Input()
    maxNumberLength: number = 40;

    /** Minimum allowed characters for extension field */
    @Input()
    minExtLength!: number;

    /** Maximum allowed characters for extension field. Default max is 20 */
    @Input()
    maxExtLength: number = 20;

    /** Function to validate number field, should return error message.
     * Will use standard validation unless set explicitly. Set to null to have no validation */
    @Input()
    numberValidationFunction: ValidationFunction<string> | null = Validation.phoneNumberValidator;

    /** Function to validate code field, should return error message */
    @Input()
    codeValidationFunction!: ValidationFunction<Country>;

    /** Function to validate extension field, should return error message.
     * Will use standard validation unless set explicitly. Set to null to have no validation */
    @Input()
    extValidationFunction: ValidationFunction<string> | null = Validation.phoneExtensionValidator;

    /** Emits current value whenever code, number, or extension fields change */
    @Output()
    valueChange = new EventEmitter<Phone>();

    @ViewChild('codeInput')
    codeInputComponent!: AutocompleteCommonComponent<Country>;

    @ViewChild('numberInput')
    numberInputComponent!: InputComponent;

    @ViewChild('extInput')
    extInputComponent!: InputComponent;

    codeItemRenderer: (data: Country) => string = (country: Country) => (country ? `+${country?.phoneCode}` : '');

    get codeRequired(): boolean {
        return (
            this.required ||
            (this.numberValue?.length > 0 && this.numberInputComponent?.valid) ||
            (this.extInputComponent?.valid && this.extValue?.length > 0)
        );
    }

    get phoneRequired(): boolean {
        return (
            this.required ||
            (this._checkIfNotNull(this.countryValue) && this.codeInputComponent?.valid) ||
            (this.extInputComponent?.valid && this.extValue?.length > 0)
        );
    }

    private _checkIfNotNull(value: string | Country | null): boolean {
        return value != '' && value != null;
    }

    onChangeValue() {
        const val = PhoneUtil.getPhoneFromNumberAndCode(
            this.countryValue?.phoneCode ?? null,
            this.numberValue,
            this.extValue
        );

        val && (this.value = val);
        // clean up error message for phone number and code fields
        if (!this._checkIfNotNull(this.countryValue) && this.numberValue?.length == 0) {
            this.numberInputComponent?.clear();
            this.codeInputComponent?.clear();
        }
    }
}
