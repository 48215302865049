import { Component, ElementRef, EventEmitter, Input, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { FormComponent } from '../form/form.component';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { isEqual } from 'lodash-es';

/** Example of autocomplete usage
 * HTML
 * IMPORTANT: Make sure that specifies itemRenderer function, otherwise autocomplete may not work correctly
 *   <vc-common-autocomplete label="Autocomplete"
 *                    [required]="true"
 *                    [(value)]="autocompleteSelected"
 *                    [options]="autocompleteOptions"
 *                    [itemRenderer]="selectItemRenderer"
 *                    (valueChange)="autocompleteChanged()"></vc-common-autocomplete>
 *
 * TS
 *     autocompleteSelected!: { position: number; name: string } | null;
 *     autocompleteOptions: { position: number; name: string }[] = [
 *         { position: 1, name: 'Hydrogen' },
 *         { position: 2, name: 'Helium' },
 *         { position: 3, name: 'Lithium' },
 *     ];
 *     public autocompleteItemRenderer = (value: { position: number; name: string }) => value.name;
 *
 *     autocompleteChanged() {
 *         ......
 *     }
 *
 */

@Component({
    selector: 'vc-common-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteCommonComponent<T> extends BaseFieldComponent<T> {
    private _filterChangeTimer!: any;

    filteredOptions!: T[];
    filter: T | string | number | null = '';

    @Input()
    set value(val: T | null) {
        if (!isEqual(val, this._value)) {
            this._value = val;
            this.filter = val;
            this.valueChange.emit(this._value);
            this.validate();
        }
    }

    get value(): T | null {
        return this._value;
    }

    private _value: T | null = null;

    /** List of options for autocomplete */
    @Input()
    set options(value: T[]) {
        this._options = value;
        this.filteredOptions = this._options;
    }

    get options(): T[] {
        return this._options;
    }

    private _options!: T[];

    /** Autocomplete placeholder value */
    @Input()
    placeholder!: string;

    /** Identifies the element (or elements) that describes the autocomplete. */
    @Input()
    ariaDescribedBy!: string;

    /** Prefix icon. */
    @Input()
    prefixIcon!: string;

    /** Suffix icon. */
    @Input()
    suffixIcon!: string;

    /** Debounce interval for filter change. */
    @Input()
    filterDebounceInterval: number = 500;

    /** Whether to show clear button. */
    @Input()
    showClear: boolean = false;

    /** Specifies value, to what reset field after invalid input */
    @Input()
    defaultValue: T | null = null;

    /** Whether to show error message. */
    @Input()
    showErrorMessage: boolean = true;

    /** Options template renderer. */
    @Input()
    optionTemplate!: TemplateRef<T>;

    /** Prefix template renderer. */
    @Input()
    prefixTemplate!: TemplateRef<T>;

    /** Function for rendering options list and selected values */
    @Input()
    itemRenderer: (data: T) => string = (value: T) => `${value}`;

    /** Two ways data binding for autocomplete value */
    @Output()
    valueChange = new EventEmitter<T | null>();

    /** Two ways data binding for filter value */
    @Output()
    filterChange = new EventEmitter<string>();

    @ViewChild('autocomplete')
    autocomplete!: MatAutocomplete;

    @ViewChild('inputElement')
    inputElement!: ElementRef;

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    selectOption(value: MatAutocompleteSelectedEvent) {
        this.value = value?.option?.value;
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if ((this.required && this._value === null) || this._value === undefined) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this._value);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.value = null);
        this.filter = '';
        this.filterChange.emit(this.filter);
        this.filteredOptions = this.options;
        this.valid = true;
        this.touched = false;
    }

    inputValueChanges() {
        this._emitFilterChange();
        this.filteredOptions = this._filter(this.filter?.toString() ?? '');
    }

    inputBlur() {
        setTimeout(() => {
            if (this.autocomplete.isOpen || this.disabled || this.readonly) {
                return;
            }
            this.touched = true;

            // Check if input value is the same as selected, if not, set selected value to null
            const matchValue = this.options?.find(
                (option: T) =>
                    (this.itemRenderer(option) ?? '').toString() === this.filter?.toString()?.trim() ||
                    isEqual(this.filter, option)
            );
            if (!matchValue) {
                this.value = this.defaultValue;
                this.filteredOptions = this.options;
                this.validate();
            } else {
                this.value = matchValue;
            }
        }, 200);
    }

    focus() {
        this.inputElement?.nativeElement?.focus();
    }

    private _filter(value: string): T[] {
        const filterValue = value?.toString()?.toLowerCase();

        return this.options?.filter((option: T) =>
            (this.itemRenderer(option) ?? '').toString()?.toLowerCase()?.includes(filterValue)
        );
    }

    private _emitFilterChange() {
        clearTimeout(this._filterChangeTimer);
        this._filterChangeTimer = setTimeout(() => {
            if (typeof this.filter === 'string' || typeof this.filter === 'number') {
                this.filterChange.emit(this.filter.toString());
            }
        }, this.filterDebounceInterval);
    }

    getContextType(obj: any): T {
        return obj;
    }
}
