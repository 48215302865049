import { Component, ContentChildren, EventEmitter, Input, OnDestroy, Optional, Output, QueryList } from '@angular/core';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { Subscription } from 'rxjs';
import { IDGenerator } from '../../common/id-generator';
import { isEqual } from 'lodash-es';
import { FormComponent } from '../form/form.component';
import { BaseFieldComponent } from '../base-field/base-field';

/** Example of checkbox group usage
 *  <vc-checkbox-group label="Severity"
 *                      [(values)]="severityChecked"
 *                      (valuesChange)="valuesChanged($event)">
 *     <vc-checkbox *ngFor="let severity of severityOptions"
 *                  [value]="severity"
 *                  [label]="severity.label"></vc-checkbox>
 *   </vc-checkbox-group>
 * TS
 *     severityOptions = [
 *         { value: 'none', label: 'None' },
 *         { value: 'low', label: 'Low' },
 *         { value: 'medium', label: 'Medium' },
 *         { value: 'high', label: 'High' },
 *         { value: 'critical', label: 'Critical' },
 *     ];
 *     severityChecked = [
 *         { value: 'none', label: 'None' },
 *         { value: 'low', label: 'Low' },
 *     ];
 *
 *    valuesChanged(values: { value: string; label: string }[]) {
 *        ......
 *     }
 * */

@Component({
    selector: 'vc-checkbox-group',
    templateUrl: './checkbox-group.component.html',
    styleUrls: ['./checkbox-group.component.scss'],
})
export class CheckboxGroupComponent<T> extends BaseFieldComponent<T> implements OnDestroy {
    private _subscription = new Subscription();

    labelId = `label-${IDGenerator.generateID()}`;
    errorId = `error-${IDGenerator.generateID()}`;

    /** Set of unique values for the checkboxes */
    @Input()
    set values(val: T[]) {
        this._values = val;
        this._setCheckboxState();
    }
    get values(): T[] {
        return this._values;
    }
    private _values: T[] = [];

    /** Specifies the direction of the checkbox items, has two different options 'row' or 'column' */
    @Input()
    direction: 'row' | 'column' = 'column';

    /** Event is fired when a value changes*/
    @Output()
    valuesChange = new EventEmitter<T[]>();

    @ContentChildren(CheckboxComponent)
    set checkboxes(values: QueryList<CheckboxComponent<T>>) {
        this._checkboxes = values;
        this._subscribeValueChange();
        this._setCheckboxState();
    }

    get checkboxes(): QueryList<CheckboxComponent<T>> {
        return this._checkboxes;
    }

    private _checkboxes!: QueryList<CheckboxComponent<T>>;

    private _setCheckboxState() {
        this._checkboxes?.forEach((checkbox: CheckboxComponent<T>) => (checkbox.checked = false));
        this._values?.forEach((value: T) => {
            const checkbox = this.checkboxes?.find((checkbox: CheckboxComponent<T>) => isEqual(checkbox.value, value));
            if (checkbox) {
                checkbox.checked = true;
            }
        });
    }

    private _subscribeValueChange() {
        this._checkboxes?.map((checkbox: CheckboxComponent<T>) => {
            this._subscription.add(
                checkbox?.checkedChange?.subscribe((checked: boolean) => {
                    if (checked && this._values?.indexOf(checkbox.value) == -1) {
                        this._values?.push(checkbox.value);
                        this.touched = true;
                    } else {
                        this._values = this._values?.filter((value: T) => !isEqual(value, checkbox.value));
                    }

                    this.validate();
                    this.valuesChange.emit(this._values);
                })
            );
        });
    }

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required && this.values.length == 0) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this.values);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.values = []);
        this.touched = false;
        this.valid = true;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
