import { Injectable } from '@angular/core';
import { clearTranslations, loadTranslations, MessageId, TargetMessage } from '@angular/localize';
import { catchError, forkJoin, map, Observable, of, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CoreConstant, Language } from '@libs/vc-core-lib';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    private _apps = [
        'admin-ui',
        'assessment-ui',
        'core-ui',
        'endpoint-ui',
        'marketplace-ui',
        'mss-ui',
        'scan-ui',
        'security-operation-ui',
        'core-lib',
    ];
    constructor(private http: HttpClient) {}

    initTranslations(): Observable<void> {
        clearTranslations();

        const lang = localStorage.getItem(CoreConstant.VC_LANGUAGE_ABBREVIATION) ?? Language.EN;

        const reqs = this._apps.map((url) =>
            this.http.get(`./assets/vc-${url}/i18n/${lang}.json`).pipe(
                take(1),
                catchError(() => {
                    console.log(`Failed to retrieve ${lang}.json for ${url}`);
                    return of({});
                })
            )
        );

        return forkJoin(reqs).pipe(
            map((results: any[]) => {
                const translations = results.reduce(
                    (previous, current) => {
                        return { ...previous, ...current };
                    },
                    {} as Record<MessageId, TargetMessage>
                );

                loadTranslations(translations as Record<string, string>);
            })
        );
    }
}
