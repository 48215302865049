@if (showFilter) {
    <vc-input
        class="vc-tree-search-input space-bottom-16"
        prefixIcon="search"
        [label]="filterLabel"
        [placeholder]="filterPlaceholder"
        [showClear]="true"
        [(value)]="filter"></vc-input>
}
<mat-tree class="vc-tree-wrapper" [style.height]="height" [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
        *matTreeNodeDef="let node"
        #nodeElement
        matTreeNodeToggle
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
        class="vc-mat-child-tree-node"
        [attr.tabindex]="getNodeTabIndex(node)"
        [attr.aria-selected]="getAriaSelected(node)"
        [class.is-hidden]="filterNode(node)"
        (click)="highlightNode(node)"
        (keydown)="nodeKeyUp($event, node, nodeElement)">
        <div
            class="vc-node-wrapper"
            [class.vc-highlightable-node]="highlightable && node.selectable"
            [class.vc-highlight-node]="checkIfHighlight(node)">
            <button mat-icon-button [disabled]="true" tabindex="-1" [attr.aria-label]="itemRenderer(node.data)"></button>
            @if (multiselect && node.selectable) {
                <mat-checkbox
                    [checked]="selection.isSelected(node)"
                    (change)="nodeLeafSelectionToggle(node)">
                    <ng-template *ngTemplateOutlet="nodeStart; context: {$implicit: node}"></ng-template>
                </mat-checkbox>
            }
            @if (!multiselect || !node.selectable) {
                <div class="vc-tree-node-label">
                    <vc-icon *ngIf="node.iconPrefix" [name]="node.iconPrefix" color="var(--primary-700)"></vc-icon>
                    <ng-template *ngTemplateOutlet="nodeStart; context: {$implicit: node}"></ng-template>
                </div>
            }
            <ng-template
                [ngTemplateOutlet]="endTemplateRef"
                [ngTemplateOutletContext]="getContextType({ $implicit: node.data })"></ng-template>
            @if (showCheckIconForHighlighted && checkIfHighlight(node)) {
                <vc-icon
                    class="vc-highlighted-node-icon"
                    name="done"
                    color="var(--text-low-emphasis)"></vc-icon>
            }
        </div>
    </mat-tree-node>

    <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        #nodeElement
        matTreeNodePadding
        matTreeNodePaddingIndent="20"
        [attr.tabindex]="getNodeTabIndex(node)"
        [attr.aria-selected]="getAriaSelected(node)"
        [class.is-hidden]="filterNode(node)"
        (click)="highlightNode(node)"
        (keydown)="nodeKeyUp($event, node, nodeElement)">
        <div
            class="vc-node-wrapper"
            [class.vc-highlightable-node]="highlightable && node.selectable"
            [class.vc-highlight-node]="checkIfHighlight(node)">
            <button mat-icon-button matTreeNodeToggle tabindex="-1" [attr.aria-label]="'Toggle ' + node.data">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right' }}
                </mat-icon>
            </button>
            @if (multiselect && node.selectable) {
                <mat-checkbox
                    [checked]="descendantsAllSelected(node)"
                    (change)="nodeSelectionToggle(node)">
                    <ng-template *ngTemplateOutlet="nodeStart; context: {$implicit: node}"></ng-template>
                </mat-checkbox>
            }
            @if (!multiselect || !node.selectable) {
                <div class="vc-tree-node-label">
                    <vc-icon *ngIf="node.iconPrefix" [name]="node.iconPrefix" color="var(--primary-700)"></vc-icon>
                    <ng-template *ngTemplateOutlet="nodeStart; context: {$implicit: node}"></ng-template>
                </div>
            }
            <ng-template
                [ngTemplateOutlet]="endTemplateRef"
                [ngTemplateOutletContext]="getContextType({ $implicit: node.data })"></ng-template>
            <vc-icon
                *ngIf="showCheckIconForHighlighted && checkIfHighlight(node)"
                class="vc-highlighted-node-icon"
                name="done"
                color="var(--text-low-emphasis)"></vc-icon>
        </div>
    </mat-tree-node>
</mat-tree>

<ng-template #nodeStart let-node>
    @if (startTemplateRef) {
        <ng-template
            [ngTemplateOutlet]="startTemplateRef"
            [ngTemplateOutletContext]="getContextType({ $implicit: node.data })"></ng-template>
    } @else {
        <vc-tooltip-text
            style="overflow: hidden"
            [class.vc-node-label-bold]="highlightLabelBold"
            [text]="itemRenderer(node.data)"></vc-tooltip-text>
    }
</ng-template>
