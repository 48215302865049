import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** Material modules */
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';

/** Common Components */
import { TableComponent } from './components/table/table.component';
import { ColumnDirective } from './components/table/column/column.directive';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { ProgressSpinnerDirective } from './directives/progress-spinner/progress-spinner.directive';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { BaseFieldComponent } from './components/base-field/base-field';
import { FormComponent } from './components/form/form.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DatePickerRangeComponent } from './components/date-picker-range/date-picker-range.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { SelectComponent } from './components/select/select.component';
import { LinkComponent } from './components/link/link.component';
import { AutocompleteCommonComponent } from './components/autocomplete/autocomplete.component';
import { RadiogroupComponent } from './components/radiogroup/radiogroup.component';
import { RadiobuttonDirective } from './components/radiogroup/radiobutton/radiobutton.directive';
import { TabGroupComponent } from './components/tab/tab-group/tab-group.component';
import { TabDirective } from './components/tab/tab.directive';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { StepperComponent } from './components/stepper/stepper/stepper.component';
import { StepDirective } from './components/stepper/step.directive';
import { ResizableColumnDirective } from './components/table/column/resizable-column.directive';
import { TreeComponent } from './components/tree/tree.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { SearchPipe } from './pipes/search.pipe';
import { AccordionComponent } from './components/accordion/accordion/accordion.component';
import { AccordionPanelComponent } from './components/accordion/accordion-panel/accordion-panel.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { InputComponent } from './components/input/input.component';
import { MenuComponent } from './components/menu/menu.component';
import { MatBadgeModule } from '@angular/material/badge';
import { IconComponent } from './components/icon/icon.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { RouterLink } from '@angular/router';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import { ListComponent } from './components/list/list.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PhoneComponent } from './components/phone/phone.component';
import { PasswordComponent } from './components/password/password.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { BadgeComponent } from './components/badge/badge.component';
import { TooltipTextComponent } from './components/tooltip-text/tooltip-text.component';
import { InputMaskPatternDirective } from './directives/input-mask-pattern/input-mask-pattern.directive';
import { NotificationBoxComponent } from './components/notification-box/notification-box.component';
import { NotificationIconPipe } from './pipes/notification-icon/notification-icon.pipe';
import { ThemeToggleComponent } from './components/theme-toggle/theme-toggle.component';
import { MoreLessTextComponent } from './components/more-less-text/more-less-text.component';
import { MatMomentDateModule, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_PICKER_FORMATS } from './common/date-formats';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
    return () => overlay.scrollStrategies.close();
}

@NgModule({
    declarations: [
        BaseFieldComponent,
        TableComponent,
        ColumnDirective,
        InputComponent,
        CheckboxComponent,
        MultiSelectComponent,
        ProgressSpinnerDirective,
        ProgressSpinnerComponent,
        CheckboxGroupComponent,
        FormComponent,
        DatePickerComponent,
        DatePickerRangeComponent,
        DialogComponent,
        SelectComponent,
        LinkComponent,
        AutocompleteCommonComponent,
        RadiogroupComponent,
        RadiobuttonDirective,
        FileUploaderComponent,
        TabGroupComponent,
        TabDirective,
        ToggleComponent,
        StepperComponent,
        StepDirective,
        TreeComponent,
        ResizableColumnDirective,
        NotificationComponent,
        CountryFlagComponent,
        CountrySelectorComponent,
        AccordionComponent,
        AccordionPanelComponent,
        TextareaComponent,
        TimePickerComponent,
        MenuComponent,
        IconComponent,
        CopyButtonComponent,
        ChipsAutocompleteComponent,
        ListComponent,
        BreadcrumbComponent,
        PhoneComponent,
        PasswordComponent,
        ActionMenuComponent,
        BadgeComponent,
        TooltipTextComponent,
        InputMaskPatternDirective,
        NotificationBoxComponent,
        NotificationIconPipe,
        ThemeToggleComponent,
        MoreLessTextComponent,
    ],
    imports: [
        MatButtonModule,
        MatTableModule,
        CommonModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatDialogModule,
        MatRadioModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatTreeModule,
        MatTooltipModule,
        DragDropModule,
        MatMenuModule,
        MatBadgeModule,
        RouterLink,
        SearchPipe,
        MatMomentDateModule,
        ButtonComponent,
    ],
    exports: [
        TableComponent,
        ColumnDirective,
        InputComponent,
        ButtonComponent,
        CheckboxComponent,
        MultiSelectComponent,
        ProgressSpinnerComponent,
        ProgressSpinnerDirective,
        CheckboxGroupComponent,
        FormComponent,
        DatePickerComponent,
        DatePickerRangeComponent,
        DialogComponent,
        SelectComponent,
        LinkComponent,
        AutocompleteCommonComponent,
        RadiogroupComponent,
        RadiobuttonDirective,
        FileUploaderComponent,
        TabGroupComponent,
        TabDirective,
        ToggleComponent,
        StepperComponent,
        StepDirective,
        TreeComponent,
        ToggleComponent,
        ResizableColumnDirective,
        NotificationComponent,
        CountrySelectorComponent,
        SearchPipe,
        AccordionComponent,
        AccordionPanelComponent,
        TextareaComponent,
        TimePickerComponent,
        MenuComponent,
        IconComponent,
        CopyButtonComponent,
        ChipsAutocompleteComponent,
        ListComponent,
        BreadcrumbComponent,
        PhoneComponent,
        PasswordComponent,
        CountryFlagComponent,
        BaseFieldComponent,
        ActionMenuComponent,
        BadgeComponent,
        TooltipTextComponent,
        InputMaskPatternDirective,
        NotificationBoxComponent,
        ThemeToggleComponent,
        MoreLessTextComponent,
    ],
    providers: [
        { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
        provideMomentDateAdapter(DATE_PICKER_FORMATS),
    ],
})
export class VcCommonUiLibModule {}
