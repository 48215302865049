<div class="vc-grid-layout fixed">
    <div class="vc-account-settings-container col-6">
        <div>
            <h2 class="vc-account-settings-security-header headline-s" i18n="@@ACCOUNT.SETTINGS.SECURITY_AND_ACCESS">
                Security & access
            </h2>
            <div class="vc-account-settings-mfa">
                <h3 id="accountMFA" class="vc-account-settings-mfa-header body-m-2" i18n="@@ACCOUNT.SETTINGS.MFA">
                    Multi-factor Authentication
                </h3>
                <vc-toggle
                    ariaLabelledby="accountMFA"
                    [(checked)]="mfaEnabled"
                    [disabled]="orgMFAEnabled"
                    (checkedChange)="beginMFAFlow($event)"></vc-toggle>
            </div>
        </div>

        <div>
            <h2 class="vc-account-settings-password-header headline-s" i18n="@@CORE.INPUT.PASSWORD">Password</h2>
            <div class="vc-account-settings-password-blurb">
                <span class="body-m-1" i18n="@@ACCOUNT.SETTINGS.USER_AVAILABLE_FUNCTIONS">
                    Use available functions below to update password
                </span>
                <vc-button
                    label="Change password"
                    i18n-label="@@ACCOUNT.CHANGE_PASSWORD"
                    mode="text"
                    [underlineText]="true"
                    (trigger)="showChangePasswordDialog = true"></vc-button>
            </div>
        </div>
        <vc-notification-box
            type="warning"
            i18n-message="@@ACCOUNT.SETTINGS.WARNING_MESSAGE"
            message="You must hit Save for changes to take effect."
            [visible]="showNotification"></vc-notification-box>
        <vc-common-form
            i18n-primaryLabel="@@CORE.BUTTON.SAVE"
            primaryLabel="Save"
            [showSecondaryButton]="true"
            [clearFieldsOnSecondary]="false"
            (secondaryTrigger)="showResetSettingsDialog = true"
            (primaryTrigger)="saveSettings()">
            <div class="vc-account-settings-form-wrapper">
                <div>
                    <h2
                        class="vc-account-settings-language-header headline-s"
                        i18n="@@ACCOUNT.SETTINGS.LANGUAGE_AND_LOCATION">
                        Language & location
                    </h2>
                    <vc-common-autocomplete
                        label="Time zone"
                        i18n-label="@@ACCOUNT.SETTINGS.TIMEZONE"
                        [(value)]="timezone"
                        [options]="timezones"
                        placeholder="Search for time zone"
                        i18n-placeholder="@@ACCOUNT.SETTINGS.SEARCH_FOR_TIMEZONE"
                        [showClear]="true"
                        [itemRenderer]="timezoneRenderer"
                        (valueChange)="settingsChange()"></vc-common-autocomplete>
                    <vc-select
                        label="Default language"
                        i18n-label="@@ACCOUNT.SETTINGS.DEFAULT_LANGUAGE"
                        [options]="languages"
                        [(value)]="language"
                        [optionTemplate]="languageTemplate"
                        [prefixTemplate]="languagePrefixTemplate"
                        (valueChange)="settingsChange()"></vc-select>
                </div>

                <div>
                    <h2 class="vc-account-settings-theme-header headline-s" i18n="@@ACCOUNT.SETTINGS.DEFAULT_THEME">
                        Default theme
                    </h2>
                    <vc-select
                        label="Theme"
                        i18n-label="@@ACCOUNT.SETTINGS.THEME"
                        [options]="themes"
                        [(value)]="theme"
                        [itemRenderer]="themeRenderer"
                        (valueChange)="settingsChange()"></vc-select>
                </div>
            </div>
        </vc-common-form>
    </div>
</div>

<vc-change-password-dialog
    [(visible)]="showChangePasswordDialog"
    [username]="username"
    (visibleChange)="showChangePasswordDialog = $event"></vc-change-password-dialog>

<vc-enable-mfa-dialog
    [(visible)]="showEnableMFA"
    (visibleChange)="onEnableVisibleChange($event)"
    (mfaComplete)="mfaComplete.emit()"></vc-enable-mfa-dialog>

<vc-disable-mfa-dialog
    [(visible)]="showDisableMFA"
    (visibleChange)="onDisableVisibleChange($event)"
    (disableMFA)="disableMFA.emit($event)"></vc-disable-mfa-dialog>

<vc-dialog
    [(visible)]="showResetSettingsDialog"
    i18n-title="@@CORE.SETTINGS.PLEASE_CONFIRM"
    title="Please confirm!"
    i18n-primaryLabel="@@CORE.SETTINGS.CONFIRM"
    primaryLabel="Confirm"
    width="500px"
    [closeOnPrimary]="true"
    (primaryTrigger)="resetSettings()">
    <p i18n="@@ACCOUNT.SETTINGS.CONFIRM_CANCEL">
        This will only cancel changes made to Settings, Time zone, Language and Theme. MFA and Change Passwords have a
        walk through process that you can cancel out of if you initiate them.
    </p>
</vc-dialog>
<ng-template #languageTemplate let-option>
    <vc-country-flag [countryClass]="option === 'en' ? 'us' : option"></vc-country-flag>
    <span>{{ getLanguageLabel(option) }} </span>
</ng-template>
<ng-template #languagePrefixTemplate let-value>
    <vc-country-flag [countryClass]="value === 'en' ? 'us' : value"></vc-country-flag>
</ng-template>
