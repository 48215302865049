import { Component, EventEmitter, Input, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { MatOptionSelectionChange } from '@angular/material/core';
import { FormComponent } from '../form/form.component';
import { isEqual } from 'lodash-es';
import { MatSelect } from '@angular/material/select';

/** Example of multiselect usage
 HTML
     <vc-multi-select
         label="Multi Select"
         [itemRenderer]="multiselectItemRenderer"
         [(values)]="selectedValues"
         [options]="options"></vc-multi-select>

 TS
     enum MyOptions{
            ZERO = 'Zero',
            ONE = 'One',
            TWO = 'Two',
            THREE = 'Three',
            FOUR = 'Four'
        }
     selectedValues!: MyOptions[];
     options = [MyOptions.ZERO, MyOptions.ONE, MyOptions.TWO, MyOptions.THREE, MyOptions.FOUR];
     multiselectItemRenderer = (value: MyOptions) => value ?? '';
 * */

@Component({
    selector: 'vc-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent<T> extends BaseFieldComponent<T> {
    otherText = $localize`:@@COMMON_UI.MULTI-SELECT.OTHER:Other`;
    othersText = $localize`:@@COMMON_UI.MULTI-SELECT.OTHERS:Others`;

    filter: string = '';

    /** Multi select label value */
    @Input()
    label: string = '';

    /** Whether to show filter*/
    @Input()
    showFilter: boolean = false;

    /** Whether to collapse dropdown on value change*/
    @Input()
    closePanelOnValueChange: boolean = false;

    /** Multi select filter label */
    @Input()
    filterLabel: string = '';

    /** Multi select filter placeholder */
    @Input()
    filterPlaceholder: string = '';

    /** Multi select filter key */
    @Input()
    filterKey: string = '';

    /** Suffix icon. */
    @Input()
    suffixIcon!: string;

    /** Count of selected values to display */
    @Input()
    displayCount: number = 3;

    @Input()
    set values(val: T[]) {
        if (!isEqual(val, this._values)) {
            this._values = val;
            this.valuesChange.emit(this._values);
            this.closePanelOnValueChange && this.selectInput?.close();
        }
    }

    get values(): T[] {
        return this._values;
    }

    private _values: T[] = [];

    /** Dropdown options */
    @Input()
    options!: T[];

    /** Whether to show clear icon. */
    @Input()
    showClear: boolean = false;

    /** Function for rendering options list and selected values */
    @Input()
    itemRenderer?: (data: T) => string;

    /** Options template renderer  */
    @Input()
    optionTemplate!: TemplateRef<T>;

    /** Two ways data binding for multi select values */
    @Output()
    valuesChange = new EventEmitter<T[]>();

    /** Event is fired when dropdown visible changes */
    @Output()
    openChange = new EventEmitter<boolean>();

    @ViewChild('selectInput')
    selectInput!: MatSelect;

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required && this.values.length === 0) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this.values);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.values = []);
        this.valid = true;
        this.errorMessage = '';
    }

    selectionChange(event: MatOptionSelectionChange<T>) {
        if (event.isUserInput && !event.source.selected) {
            this.values = this.values?.filter((value: T) => event.source.value != value);
        }

        if (event.isUserInput && event.source.selected) {
            if (this.values) {
                this.values = [...this.values, event.source.value];
            } else {
                this.values = [event.source.value];
            }
        }
    }

    openedChange(value: boolean) {
        this.openChange.emit(value);
        this.filter = '';
    }

    displaySelected() {
        if (!this.values) {
            return '';
        }
        let displayVal: string[] = [];
        let displayValues = this.values.slice(0, this.displayCount);

        displayValues.forEach((item: T) => {
            this.itemRenderer != null ? displayVal.push(this.itemRenderer(item)) : displayVal.push(item as string);
        });

        return this.values.length > this.displayCount
            ? `${displayVal.join(', ')} (+ ${(this.values.length || 0) - 1} ${this.othersText})`
            : displayVal.join(', ');
    }

    getContextType(obj: any): T {
        return obj;
    }
}
