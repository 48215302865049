import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boldBySearch',
    standalone: true,
})
export class BoldBySearch implements PipeTransform {
    public transform(value: string, searchValue: string): string {
        if (!searchValue) return value;

        for (let word of searchValue.split(' ')) {
            const exp = new RegExp(`${word}`, 'i');
            const match =  value.match(exp);

            if (word && match) {
                value = value.replace(exp, `<b>${match}</b>`)
            }
        }

        return value;
    }
}
