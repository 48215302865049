import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService, IJsonPatchModel, PageResponse, PageUtil } from '@libs/vc-core-lib';
import { IOrgCriteriaDTO } from '../dto/org.criteria.dto';
import { IOrgModel } from '../model/org.model';
import { map, Observable } from 'rxjs';
import { IOrgSearchDTO } from '../dto/org.search.dto';
import { Page } from '@libs/vc-common-ui-lib';

@Injectable()
export class OrgService extends BaseService<IOrgModel> {
    constructor(protected injector: Injector) {
        super(injector, 'org');
    }

    public pageSearch(searchDTO: IOrgSearchDTO): Observable<Page<IOrgModel>> {
        return this.httpClient
            .post<
                PageResponse<IOrgModel>
            >(`${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/pageSearch`, searchDTO)
            .pipe(map((response) => PageUtil.convertToPage(response)));
    }

    public getOrgsForSwitch(): Observable<IOrgModel[]> {
        return this.httpClient.get<IOrgModel[]>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/switch`
        );
    }

    public setOrgForSwitch(id: string): Observable<IOrgModel> {
        return this.httpClient.post<IOrgModel>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/switch/${id}`,
            {}
        );
    }

    public getOrgNamesForIds(ids: string[]): Observable<Map<string, string>> {
        return this.httpClient.post<Map<string, string>>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/orgNamesForIds`,
            ids
        );
    }

    public patchOrg(orgId: string, models: Array<IJsonPatchModel>): Observable<IOrgModel> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json-patch+json');
        const httpOptions = {
            headers: headers,
        };
        return this.httpClient.patch<IOrgModel>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/update/${orgId}`,
            models,
            httpOptions
        );
    }

    public getOrgIdsInHierarchy(orgId: string): Observable<string[]> {
        return this.httpClient.get<string[]>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/${orgId}/hierarchy-up`
        );
    }

    public getOrgById(id: string): Observable<IOrgModel> {
        return this.httpClient.get<IOrgModel>(`${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/${id}`);
    }

    public updateOrg(model: IOrgModel): Observable<IOrgModel> {
        return this.httpClient.post<IOrgModel>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/update`,
            model
        );
    }

    public searchOrgs(orgCriteriaDTO: IOrgCriteriaDTO): Observable<IOrgModel[]> {
        return this.httpClient.post<IOrgModel[]>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/search`,
            orgCriteriaDTO
        );
    }

    public deleteOrg(id: string): Observable<null> {
        return this.httpClient.delete<null>(`${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/${id}`);
    }

    public saveOrg(model: IOrgModel): Observable<IOrgModel> {
        return this.httpClient.post<IOrgModel>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/create`,
            model
        );
    }

    public getOrgByName(name: string): Observable<IOrgModel[]> {
        return this.httpClient.post<IOrgModel[]>(
            `${this.environment.APP.VC_ORG_BASE_URL}/${this.serviceBaseUrl}/name`,
            {
                name: name,
            }
        );
    }
}
