<h1 class="vc-forgot-form-greeting headline-l" i18n="@@ACCOUNT.RESET_PASSWORD">Reset password</h1>
<span id="vc-forgot-form-description" style="display: none">Forgot password form</span>

<vc-common-form
    #forgotPasswordForm
    class="vc-forgot-common-form"
    [ariaDescribedBy]="'vc-forgot-form-description'"
    [showPrimaryButton]="false"
    [showSecondaryButton]="false">
    <vc-input
        label="Username"
        i18n-label="@@LOGIN.FORM.USERNAME"
        [required]="true"
        [autocomplete]="'username'"
        [(value)]="username"></vc-input>
    <div buttons class="vc-forgot-form-buttons">
        <vc-button
            type="submit"
            mode="primary"
            label="Get token"
            i18n-label="@@LOGIN.GET_TOKEN"
            [width]="'160px'"
            [loading]="submitLoading"
            (trigger)="getToken()"></vc-button>
        <vc-button
            mode="basic"
            label="Have token?"
            i18n-label="@@LOGIN.HAVE_TOKEN"
            [width]="'160px'"
            (trigger)="navigateToResetPassword()"></vc-button>
    </div>
</vc-common-form>

<div class="vc-forgot-form-links">
    <vc-link
        label="Return to login"
        i18n-label="@@LOGIN.RETURN_TO_LOGIN"
        [routerLink]="'/login'"
        [target]="'_self'"
        [color]="'var(--primary-700)'"
        [underlineText]="true"></vc-link>
    <vc-link
        label="Need help?"
        i18n-label="@@LOGIN.NEED_HELP"
        [underlineText]="true"
        [color]="'var(--primary-700)'"
        [url]="'https://www.vikingcloud.com/contact?tab=support'"
        [showOpenInNewWindow]="true"></vc-link>
</div>
