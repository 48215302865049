export enum Priority {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export class PriorityHelper {
    static getPriorityString(status: Priority): string {
        switch (status) {
            case Priority.LOW:
                return $localize`:@@CORE.PRIORITIES.LOW:Low`;
            case Priority.MEDIUM:
                return $localize`:@@CORE.PRIORITIES.MEDIUM:Medium`;
            case Priority.HIGH:
                return $localize`:@@CORE.PRIORITIES.HIGH:High`;
            default:
                return '';
        }
    }
}
