import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    ViewChild,
} from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { FormComponent } from '../form/form.component';
import { DateRange } from '../../interfaces/date-range';
import { DATE_PICKER_FORMATS, DateFormats } from '../../common/date-formats';
import { ActionItem } from '../../interfaces/action-item';
import { MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import moment from "moment";

/** Example of date picker range usage
 * HTML
 *  <vc-date-picker-range label="Date Range"
 *                         [required]="true"
 *                         [(value)]="range"
 *                         (valueChange)="changeRangeDate($event)"></vc-date-picker-range>
 *
 * TS
 * range: DateRange = { startDate: new Date(), endDate: new Date() };
 *
 * changeRangeDate(date: DateRange) {
 *   ......
 * }
 * */

@Component({
    selector: 'vc-date-picker-range',
    templateUrl: './date-picker-range.component.html',
    styleUrls: ['./date-picker-range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS},
    ]
})
export class DatePickerRangeComponent extends BaseFieldComponent<DateRange> {
    private startDateText = $localize`:@@COMMON_UI.DATE_PICKER.START_DATE:Start date`;
    private endDateText = $localize`:@@COMMON_UI.DATE_PICKER.END_DATE:End date`;

    startDate!: Date | null;
    endDate!: Date | null;
    touched: boolean = false;

    dateFormats = DateFormats;

    @Input()
    set value(val: DateRange) {
        if (val?.startDate !== this._value?.startDate || val?.endDate !== this._value?.endDate) {
            this._value = val ?? {};

            // set start date to the very first moment of the day so that results include that entire day
            this._value.startDate && (this._value.startDate = new Date(new Date(this._value.startDate).setHours(0, 0, 0, 0)));
            this.startDate = this._value?.startDate ?? null;

            // set end date to the very last moment of the day so that results include that entire day
            this._value.endDate && (this._value.endDate = new Date(new Date(this._value.endDate).setHours(23, 59, 59, 999)));
            this.endDate = this._value?.endDate ?? null;

            this.valueChange.emit(this._value);
        }
    }
    get value(): DateRange {
        return this._value;
    }
    private _value!: DateRange;

    /** Specifies minimum date value what can be set. */
    @Input()
    minDate!: Date | null;

    /** Specifies maximum date value what can be set. */
    @Input()
    maxDate!: Date | null;

    /** Start date placeholder value */
    @Input()
    startDatePlaceholder: string = this.startDateText;

    /** Date range tooltip text */
    @Input()
    tooltip!: string;

    /** Date range tooltip position */
    @Input()
    tooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' = 'above';

    /** Whether not show tooltip */
    @Input()
    tooltipDisabled: boolean = false;

    /** End date placeholder value */
    @Input()
    endDatePlaceholder: string = this.endDateText;

    /** Whether to show clear button  */
    @Input()
    showClear: boolean = true;

    /** Whether to validate field on value changes. */
    @Input()
    validateOnValueChange: boolean = false;

    /** Whether to show custom action menu  */
    @Input()
    showActionMenu: boolean = false;

    /** Whether to show custom action menu  */
    @Input()
    actionItems: ActionItem<any>[] = [];

    /** Two ways data binding for date range value */
    @Output()
    valueChange = new EventEmitter<DateRange>();

    @Output()
    actionClick = new EventEmitter<ActionItem<any>>();

    @ViewChild('startDateInput')
    startDateInput!: ElementRef<HTMLInputElement>;

    @ViewChild('endDateInput')
    endDateInput!: ElementRef<HTMLInputElement>;

    @ViewChild('rangePicker')
    rangePicker!: MatDateRangePicker<any>;

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    valueChanged() {
        if ((this.startDate || this.endDate) && !this.touched) {
            this.touched = true;
        }

        this.value = { startDate: this.startDate, endDate: this.endDate };
        if (this.touched) {
            this.validate();
        }
    }

    onBlur() {
        this.touched = true;
        this.validate();
    }

    onKeyDown() {
        if (this.validateOnValueChange) {
            this.touched = true;
            this.validate();
        }
    }

    onKeyUp() {
        if (this.validateOnValueChange) {
            this.touched = true;
            this.validate();
        }
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required) {
            if (!this.startDate && !this.endDate) {
                this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
                this.valid = false;
            }

            if (!this.startDate && this.endDate) {
                this.errorMessage = `${this.startDatePlaceholder ?? this.startDateText} ${this.requiredText}`;
                this.valid = false;
            }

            if (this.startDate && !this.endDate) {
                this.errorMessage = `${this.endDatePlaceholder ?? this.endDateText} ${this.requiredText}`;
                this.valid = false;
            }
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this._value);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    openRangePicker() {
        this.rangePicker && this.rangePicker.open();
    }

    clear() {
        if (!this.readonly && !this.disabled) {
            this.startDate = null;
            this.endDate = null;
            this.startDateInput?.nativeElement && (this.startDateInput.nativeElement.value = '');
            this.endDateInput?.nativeElement && (this.endDateInput.nativeElement.value = '');
            this.value = { startDate: null, endDate: null };
        }
        this.errorMessage = '';
        this.valid = true;
        this.touched = false;
    }

    onStartDateChange(startDate: MatDatepickerInputEvent<any>)  {
        this.value = {...this.value, startDate: moment(startDate.value).toDate()}
    }

    onEndDateChange(endDate: MatDatepickerInputEvent<any>)  {
        this.value = {...this.value, endDate: moment(endDate.value).toDate()}
    }
}
