<div class="vc-grid-layout fixed" [vcProgressSpinner]="loading">
    <div class="col-12">
        <h1 i18n="@@ACCOUNT" class="headline-l">Account</h1>
        <vc-tab-group>
            <ng-template vcTab i18n-label="@@ACCOUNT.DETAILS" label="Details" link="/account/details">
                <div class="vc-form-container vc-account-details-wrapper vc-grid-layout fixed">
                    <vc-user-details
                        class="vc-account-details-tab col-6"
                        [user]="user"
                        [orgs]="(orgs$ | async) ?? []"
                        [readonlyOrg]="true"
                        [showLanguageSelector]="false"
                        [showSecondaryButton]="true"
                        [resetToInitialOnSecondary]="true"
                        (submitForm)="updateUserDetails($event)"></vc-user-details>
                </div>
            </ng-template>
            <ng-template vcTab i18n-label="@@ACCOUNT.SETTINGS" label="Settings" link="/account/settings">
                <vc-account-settings
                    [user]="user"
                    [orgMFAEnabled]="orgMFAEnabled"
                    [showEnableMFA]="showEnableMFA"
                    [showDisableMFA]="showDisableMFA"
                    [mfaEnabled]="mfaEnabled"
                    [previousMfaEnabled]="mfaEnabled"
                    [timezones]="timezones"
                    [language]="language"
                    [prevLanguage]="previousLanguage"
                    [languages]="languages"
                    [theme]="theme$ | async"
                    [prevTheme]="previousTheme"
                    [themes]="themes"
                    (disableMFA)="disableMFA($event)"
                    (timeZoneChange)="updateUserDetails($event)"
                    (userSettingsChange)="saveSettings($event)"
                    (mfaComplete)="fetchUserDetails()"></vc-account-settings>
            </ng-template>
        </vc-tab-group>
    </div>
</div>
