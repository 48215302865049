<mat-form-field
    class="vc-autocomplete-container vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-readonly]="readonly"
    [class.mat-form-field-invalid]="!valid && touched"
    [class.vc-filter-minimal-styles]="minimalStyles">
    <mat-label>{{ label }}</mat-label>
    <input
        #inputElement
        type="text"
        matInput
        [attr.aria-invalid]="!valid"
        [attr.aria-describedby]="ariaDescribedBy"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [disabled]="disabled"
        [required]="required"
        [matAutocomplete]="auto"
        (blur)="inputBlur()"
        [(ngModel)]="filter"
        (ngModelChange)="inputValueChanges()" />
    <mat-autocomplete
        #autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="itemRenderer"
        (optionSelected)="selectOption($event)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
            <vc-tooltip-text
                *ngIf="!optionTemplate"
                [text]="itemRenderer(option)"
                [position]="'after'"></vc-tooltip-text>
            <ng-container
                [ngTemplateOutlet]="optionTemplate"
                [ngTemplateOutletContext]="getContextType({ $implicit: option })"></ng-container>
        </mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
    <div *ngIf="prefixTemplate && value" matPrefix>
        <ng-container
            [ngTemplateOutlet]="prefixTemplate"
            [ngTemplateOutletContext]="getContextType({ $implicit: value })"></ng-container>
    </div>
    <mat-icon *ngIf="suffixIcon && !filter" matSuffix>{{ suffixIcon }}</mat-icon>
    <vc-button
        *ngIf="filter && showClear && !readonly && !disabled"
        matSuffix
        mode="icon"
        iconName="close"
        ariaLabel="Clear"
        (trigger)="clear(); focus()">
    </vc-button>
    <mat-hint *ngIf="!valid && touched && showErrorMessage">
        <span class="mat-error vc-error-message">{{ errorMessage }}</span>
    </mat-hint>
</mat-form-field>
