@if (mode !== 'icon') {
    <button
        mat-raised-button
        color="{{ buttonMode }}"
        matTooltip="{{ tooltip }}"
        [class.vc-text-button]="mode === 'text'"
        [class.vc-danger-button]="mode === 'danger'"
        [class.vc-danger-text-button]="mode === 'danger-text'"
        [class.vc-link-button]="inline"
        [style.color]="textColor"
        [style.line-height]="height"
        [style.font-size]="fontSize"
        [style.height]="height"
        [style.width]="width"
        [style.text-decoration]="underlineText ? 'underline' : 'none'"
        [type]="type"
        [tabIndex]="tabIndex"
        [disabled]="disabled || loading"
        [matTooltipPosition]="tooltipPosition"
        [attr.aria-label]="ariaLabel"
        [attr.aria-describedby]="ariaDescribedby"
        [matMenuTriggerFor]="triggerFor"
        (click)="trigger.emit($event)">
        @if (loading) {
            <mat-icon class="vc-common-button-loading">autorenew</mat-icon>
        }
        @if (iconPrefix && !loading) {
            <mat-icon>{{ iconPrefix }}</mat-icon>
        }
        {{ label }}
        @if (iconSuffix) {
            <mat-icon>{{ iconSuffix }}</mat-icon>
        }
    </button>
}
@if (mode === 'icon') {
    <button
        mat-icon-button
        matTooltip="{{ tooltip }}"
        [tabIndex]="tabIndex"
        [class.vc-icon-button-color]="!disabled"
        [style.color]="!disabled ? iconColor : ''"
        [type]="type"
        [disabled]="disabled"
        [matTooltipPosition]="tooltipPosition"
        [attr.aria-label]="ariaLabel"
        [attr.aria-describedby]="ariaDescribedby"
        [matMenuTriggerFor]="triggerFor"
        (click)="trigger.emit($event)">
        <mat-icon>{{ iconName }}</mat-icon>
    </button>
}

@if (badgeIcon) {
    <mat-icon class="badge-icon">{{ badgeIcon }}</mat-icon>
}
