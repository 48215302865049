import { Component, OnInit, HostListener, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {
    AuthorityUtil,
    UrlPrivilegeUtilService,
    NavigationMenu,
    LoginService,
    WhoAmI,
    CommonUtil,
    Language,
    NotificationCriteria,
    Notification,
    NotificationService,
    StringUtil,
    UserPreferenceService,
    UserContextService,
    UserService,
    Theme,
    IUserKeyModel,
} from '@libs/vc-core-lib';
import { IOrgModel, OrgService } from '@libs/vc-org-lib';
import { delay, finalize, map, Observable, of, Subject, take, takeUntil } from 'rxjs';
import {
    Breadcrumb,
    BreadcrumbsService,
    NotificationService as NotificationToastService,
} from '@libs/vc-common-ui-lib';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

declare let pendo: any;

@Component({
    selector: 'post-login-layout',
    styleUrls: ['./post-login-layout.component.scss'],
    templateUrl: './post-login-layout.component.html',
    providers: [OrgService, NotificationService],
})
export class PostLoginLayoutComponent implements OnInit, OnDestroy {
    public userFullname: string = '';

    public loading: boolean = true;
    public usingMfa: boolean = false;
    public isImpersonatingRole: boolean = false;
    public forcePasswordChangeDialog: boolean = false;
    public forceMFA: boolean = false;
    public showEnableMFA: boolean = false;
    public isOrgAdmin: boolean = false;

    public navigationTree: NavigationMenu[] = [];
    public breadcrumbs: Breadcrumb[] = [];
    public orgModels$!: Observable<IOrgModel[]>;
    public user!: WhoAmI;
    public theme!: Theme | null;

    public notifications$!: Observable<Notification[]>;

    private _userActivity!: any;
    private _timeout = 15 * 60 * 1000; // 15 minutes timeout
    private _userInactive: Subject<undefined> = new Subject();
    private _destroy$: Subject<void> = new Subject();

    @HostListener('window:keyup')
    @HostListener('window:mousemove')
    refreshUserState() {
        clearTimeout(this._userActivity);
        this._setTimeout();
    }

    @ViewChild('mainContent')
    mainContent!: ElementRef;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router,
        private _urlPrivilegeUtilService: UrlPrivilegeUtilService,
        private _loginService: LoginService,
        private _orgService: OrgService,
        private _userService: UserService,
        private _userContextService: UserContextService,
        private _userPreferenceService: UserPreferenceService,
        private _breadcrumbsService: BreadcrumbsService,
        private _notificationService: NotificationService,
        private _notificationToastService: NotificationToastService,
        private _dialogRef: MatDialog
    ) {
        CommonUtil.showAppLoader();

        this._breadcrumbsService
            .getBreadcrumbs()
            .pipe(takeUntil(this._destroy$))
            .subscribe((breadcrumbs: Breadcrumb[]) => {
                this.breadcrumbs = breadcrumbs ?? [];
            });

        clearTimeout(this._userActivity);
        this._setTimeout();

        this._userInactive.pipe(takeUntil(this._destroy$)).subscribe(async () => {
            clearTimeout(this._userActivity);
            await this.logout();
        });
    }

    ngOnInit() {
        this._userContextService
            .getContext()
            .pipe(takeUntil(this._destroy$))
            .subscribe((user: WhoAmI | null) => {
                if (!user) return;

                this.user = user;
                this.usingMfa = user.usingMfa ?? false;
                this.isOrgAdmin = user.hasAuthority(AuthorityUtil.ROLE_ORG_ADMIN);
                this.forceMFA = user.hasAuthority(AuthorityUtil.ROLE_TEMP_MFA_ROLE);
                this._document.documentElement.lang = this.user.locale as string;
                this._checkImpersonation();
                this._getNotificationHistory();
                this._getOrgs();
                this._getNavigationMenu(this.user);
                this._initPendo(user);

                const userKey: IUserKeyModel = {
                    username: user.getUsername(),
                    authenticator: user.getAuthenticator(),
                } as IUserKeyModel;

                this._userPreferenceService
                    .initializeUserPreferences(userKey)
                    .pipe(
                        delay(500),
                        take(1),
                        finalize(() => {
                            this.forcePasswordChangeDialog =
                                !this.isImpersonatingRole && (!!user.forcePasswordChange || !!user.credentialsExpired);
                            // Do not show MFA dialog if user needs to reset password
                            if (!this.forcePasswordChangeDialog) {
                                this.showEnableMFA = this.forceMFA;
                            }
                            this._userPreferenceService
                                .getUserTheme()
                                .pipe(takeUntil(this._destroy$))
                                .subscribe((theme: Theme | null) => {
                                    this.theme = theme;
                                    CommonUtil.hideAppLoader();
                                    this.loading = false;
                                });
                        })
                    )
                    .subscribe({
                        error: () => {
                            this._notificationToastService.error(
                                '',
                                $localize`:@@ERROR.FETCHING_USER_PREFERENCES:Error while fetching user preferences.`
                            );
                        },
                    });
            });
    }

    public logout() {
        CommonUtil.showAppLoader();
        if (this.isImpersonatingRole) {
            this.endImpersonation();
        } else {
            this._loginService.logout().pipe(take(1)).subscribe({
                next: this._clearSessionAndLogout,
                error: this._clearSessionAndLogout,
            });
        }
    }

    public changeTheme(theme: Theme) {
        CommonUtil.showAppLoader();
        this._userPreferenceService.saveUserTheme(theme).pipe(take(1)).subscribe();
    }

    public changeLang(lang: Language) {
        this._document.documentElement.lang = lang;
        this._saveUserLanguage(lang);
    }

    public selectOrg(id: string) {
        this.loading = true;
        this._orgService
            .setOrgForSwitch(id)
            .pipe(
                take(1),
                finalize(() => (this.loading = false))
            )
            .subscribe(() => {
                window.location.reload();
            });
    }

    public endImpersonation() {
        CommonUtil.showAppLoader();
        this._loginService.logout().pipe(take(1)).subscribe({
            next: this._logoutImpersonateUser,
            error: this._logoutImpersonateUser,
        });
    }

    public skipToMainContent(): void {
        this.mainContent?.nativeElement?.focus();
    }

    public updateForcePasswordChange(success: boolean) {
        if (success) {
            this.forcePasswordChangeDialog = false;
            this.user.forcePasswordChange = false;
            this._userContextService.setContext(this.user);
        }
    }

    public onUpdateMFA() {
        this.user.usingMfa = true;
        this._userContextService.setContext(this.user);
        this.logout();
    }

    private _checkImpersonation() {
        this.isImpersonatingRole = this.user.hasAuthority(AuthorityUtil.PERMISSION_ROLE_PREVIOUS_IMPERSONATOR);
        this.userFullname = '';

        if (this.isImpersonatingRole) {
            if (this.user) {
                if (StringUtil.isBlankWithTrim(this.user.getUserFullName())) {
                    this.userFullname = this.user.username ?? '';
                } else {
                    this.userFullname = `(${this.user.getUserFullName()})`;
                }
            }
        }
    }

    private _getOrgs() {
        this._orgService
            .getOrgById(this.user.getOrgId())
            .pipe(takeUntil(this._destroy$))
            .subscribe((orgModel: IOrgModel) => {
                if (!!orgModel?.name) {
                    this.orgModels$ = this._orgService.getOrgsForSwitch();
                } else {
                    this.orgModels$ = of();
                }
            });
    }

    private _getNavigationMenu(model: WhoAmI) {
        let menuItems: NavigationMenu[] = [];
        const visibleItems = this._urlPrivilegeUtilService.getVisibleURLPrivileges();

        visibleItems.forEach((menu: NavigationMenu) => {
            if (menu?.childMenus && menu?.childMenus?.length > 0) {
                const parentAuthority = this._hasAuthority(menu.privileges, model);
                if (parentAuthority) {
                    const parent = menu;
                    const childMenus = [...menu.childMenus];
                    parent.childMenus = [];
                    childMenus?.forEach((childMenu: NavigationMenu) => {
                        this._hasAuthority(childMenu.privileges, model) &&
                            childMenu.visible &&
                            parent.childMenus?.push(childMenu);
                    });

                    menuItems.push(parent);
                }
            } else {
                this._hasAuthority(menu.privileges, model) && menu.visible && menuItems.push(menu);
            }
        });

        this.navigationTree = menuItems;
    }

    private _hasAuthority(listPrivileges: string[] | undefined, model: WhoAmI): boolean {
        if (!listPrivileges) {
            return false;
        }

        return listPrivileges.some((value: string) => (model?.authorities?.indexOf(value) ?? -1) > -1);
    }

    private _setTimeout() {
        this._userActivity = setTimeout(() => this._userInactive.next(undefined), this._timeout);
    }

    private _getNotificationHistory() {
        if (!this.user) {
            return;
        }

        const criteria: NotificationCriteria = {
            recipient: {
                username: this.user.username ?? '',
            },
        } as NotificationCriteria;

        this.notifications$ = this._notificationService
            .getNotifications(criteria)
            .pipe(
                map((data: Notification[]) =>
                    data?.reverse()?.filter((value: Notification) => value.shortSubject && value.shortDescription)
                )
            );
    }

    private _clearSessionAndLogout = async () => {
        this._userContextService.clear();
        this._userPreferenceService.clear();
        this._closeSpinnersAndDialogs();
        await this._router.navigate(['login']);
    };

    private _logoutImpersonateUser = async () => {
        this._userContextService.clearSessionOnly();
        this._userPreferenceService.clear();
        this._closeSpinnersAndDialogs();
        await this._router.navigate(['login']);
    };

    private _closeSpinnersAndDialogs() {
        this._dialogRef.closeAll();
        this.loading = false;
    }

    private _initPendo(user: WhoAmI) {
        pendo.initialize({
            visitor: {
                id: user?.username,
                email: user?.email,
            },
            account: {
                id: user?.getOrgId(),
            },
        });
    }

    private _saveUserLanguage(lang: Language) {
        if (!this?.user?.username) {
            return;
        }

        CommonUtil.showAppLoader();
        this._userService
            .saveUserLocale(this.user.username, lang)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    CommonUtil.changeLanguage(lang);
                },
                error: () => {
                    CommonUtil.hideAppLoader();
                },
            });
    }

    ngOnDestroy(): void {
        clearTimeout(this._userActivity);
        this._destroy$.next();
        this._destroy$.complete();
    }
}
