import { Component, EventEmitter, Input, Output } from '@angular/core';

/** Example of input usage
 * HTML
 *    <vc-more-less-text
 *       text="Lorem ipsum dolor sit amet,
 *       consectetur adipiscing elit, sed do eiusmod tempor
 *       incididunt ut labore et dolore magna aliqua. Hendrerit
 *       dolor magna eget est. Cursus in hac habitasse platea.
 *       unc pulvinar sapien et ligula ullamcorper malesuada."
 *       [characterLimit]="50">
 *    </vc-more-less-text>
 * */

@Component({
    selector: 'vc-more-less-text',
    templateUrl: './more-less-text.component.html',
    styleUrls: ['./more-less-text.component.scss'],
})
export class MoreLessTextComponent {
    /** Text value. */
    @Input()
    text: string = '';

    /** Button label when showMore equal true. */
    @Input()
    showMoreLabel: string = 'Show more';

    /** Button label when showMore equal false. */
    @Input()
    showLessLabel: string = 'Show less';

    /** Whether to show all text. */
    @Input()
    showMore: boolean = false;

    /** Characters limit to display when showMore equal false. */
    @Input()
    characterLimit: number = 300;

    /** Event is fired when show more/less button triggered */
    @Output()
    showMoreChange = new EventEmitter<boolean>();

    get textExceedLimit() {
        return this.text?.length > this.characterLimit;
    }
}
